import { CommandCategory, RoomCommand } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';
import LockType from 'model/LockType';

export const getRoomTypes = async (siteName: string) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.RoomService,
      RoomCommand.GetRoomTypes,
      [],
    );
    if (!data || !data.data) {
      notifyCommandError('No lock type found found or the server timed out.', null);
      return [];
    }
    const resultList: LockType[] = data.data;
    return resultList;
  } catch (err) {
    notifyCommandError('Error while executing GetRoomTypes command', getAxiosErrorOrNull(err));
    return [];
  }
};
