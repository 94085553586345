import * as React from 'react';
import { groupsColData, roomsColData } from 'constants/customColData';
import { StyledContentHolder, StyledContentRowHolder, StyledUserDialogContent } from '../UserDialogCommonStyle';
import styled from 'styled-components';
import StyledButton from 'components/controls/button/StyledButton';
import { OPACITY_OF_ROWS, veryLightGrey, white } from 'constants/theme';
import addBlack from 'media/addBlack.svg';
import TabsField from 'components/controls/tabs/TabsField';
import CustomTable from 'components/cards/CustomTable';
import BaseUserAccessDialog from './accessPageDialogs/BaseUserAccessDialog';
import AddRoomsDialog from './accessPageDialogs/AddRoomsDialog';
import { clearBuildings, fetchAllBuildings } from 'redux/actions/buildingActions';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { connect, useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import UserAccessDateSelectorCard from './accessPageCards/UserAccessDateSelectorCard';
import RoomModel from 'model/RoomModel';
import { alpha } from '@mui/material/styles';
import { DoorGroupType, UserRoom } from 'model/NewUserModel';
import AccessGroupDialog from './accessPageDialogs/AddGroupsDialog';
import { clearRooms, fetchAllRooms } from 'redux/actions/roomActions';
import { selectRoomList } from 'redux/selectors/roomSelector';
import DoorGroupModel from 'model/DoorGroupModel';
import { DoorGroupTableRecord } from 'model/DoorGroupTableRecord';
import { fetchAllDoorGroups } from 'redux/actions/doorGroupActions';
import { notifyError } from 'helper/NotificationService';
import { getUserDoorGroupRoomsNames } from 'helper/CommonUtils';
import { AccessTabs } from 'model/enums';
import { useBuildingsInit } from 'hooks/useStorageInits';

export const UserAccessPage = (props: any) => {

  const TABS_FIELD_OPTIONS = ['Groups', 'Rooms'];
  const ADD_BUTTON_TEXT = ['Add Groups', 'Add Rooms'];

  const getDoorGroupListItems = (): DoorGroupModel[] => {
    const doorGroupNames = props.form.doorGroups.map(doorGroup => doorGroup.GroupName);
    return props.listOfDoorGroups.map((doorGroup) => ({doorGroup, isChecked : doorGroupNames.includes(doorGroup.GroupName)}));
  }

  const dispatch = useDispatch();
  const buildings = useBuildingsInit();
  const listOfAllRooms = useSelector(selectRoomList);
  const actualSite = ActualSiteLocator();
  const [selectedTab, setSelectedTab] = React.useState<AccessTabs>(AccessTabs.GroupsTab);
  const [selectedRooms, setSelectedRooms] = React.useState<RoomModel[]>([]);
  const [isAddRoomsDialogOpen, setAddRoomsDialogOpen] = React.useState(false);
  const [isAddGroupsDialogOpen, setAddGroupsDialogOpen] = React.useState<boolean>(false);
  const [displayedRoomModels, setDisplayedRoomModels] = React.useState<RoomModel[]>([]);
  const [doorGroupList, setDoorGroupList] = React.useState<DoorGroupModel[]>(getDoorGroupListItems());
  const [displayedDoorGroups, setDisplayedDoorGroups] = React.useState<DoorGroupTableRecord[]>([]);

  const handleGroupsTabFetchRequest = () => {
    // Loading the Door Groups and storing them in the redux storage.
    fetchAllDoorGroups(actualSite, dispatch);
  };

  const handleRoomsTabFetchRequest = () => {
    
    // Loading the Buildings and storing them in the redux storage.
    dispatch(clearBuildings());
    props.fetchBuildings(actualSite);

    // Loading the Rooms and storing them in the redux storage.
    dispatch(clearRooms());
    fetchAllRooms(actualSite, dispatch);
  };

  React.useEffect(() => {
    return selectedTab === AccessTabs.GroupsTab 
      ? handleGroupsTabFetchRequest()
      : handleRoomsTabFetchRequest();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedTab]);

  React.useEffect(() => {
    if (listOfAllRooms && listOfAllRooms.length > 0 && props.form.userRooms) {
      const userRoomIds = props.form.userRooms
        .map(userRoom => userRoom.RoomId)
          .filter((room) => !getUserDoorGroupRoomsNames(doorGroupList).map(uRooom => uRooom.RoomId).includes(room.RoomId));

      const roomsToDisplay: RoomModel[] = listOfAllRooms.filter((room) => userRoomIds.includes(room.Id));     
      setDisplayedRoomModels(roomsToDisplay);
      setSelectedRooms(roomsToDisplay);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [listOfAllRooms, doorGroupList]);

  React.useEffect(() => {
    if(!isAddRoomsDialogOpen) {
      setSelectedRooms(displayedRoomModels);
    }
  }, [isAddRoomsDialogOpen, displayedRoomModels])

  React.useEffect(() => {
    const userRooms: UserRoom[] = displayedRoomModels.map((item: RoomModel) => ({
      RoomId: item.Id,
      RoomName: item.Name,
    }));
    props.setForm({...props.form, userRooms});
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedRoomModels]);

  React.useEffect(() => {
    if (props.listOfDoorGroups && props.form.doorGroups) {
      setDoorGroupListBasedOnForm();
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listOfDoorGroups])

  React.useEffect(() => {
    if (doorGroupList) {
      setDisplayedDoorGroups(doorGroupList.filter((item) => item.isChecked).map((item) => ({
        groupName: item.doorGroup.GroupName,
        doors: item.doorGroup.Rooms.length,
      })));
    }
  }, [doorGroupList]);

  React.useEffect(() => {
    if (!isAddGroupsDialogOpen) {
      const doorGroups: DoorGroupType[] = doorGroupList
      .filter((item: DoorGroupModel) => item.isChecked)
      .map((item: DoorGroupModel) => ({
        Id: item.doorGroup.Id,
        GroupName: item.doorGroup.GroupName,
      }));
      props.setForm({...props.form, doorGroups})
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayedDoorGroups, isAddGroupsDialogOpen]);

  const handleClickOnAddRooms = () => {setAddRoomsDialogOpen(true);}

  const handleClickOnRoomsConfirm = () => {
    setDisplayedRoomModels(listOfAllRooms.filter((room) => selectedRooms.some(selectedRoom => selectedRoom.Id === room.Id)));
    setAddRoomsDialogOpen(false);
  };

  const handleClickOnRoomRemove = (row: RoomModel) => {
    setDisplayedRoomModels(prev => prev.filter((item) => item.Name !== row.Name));
  }

  const handleClickOnAddGroups = () => {setAddGroupsDialogOpen(true);}

  const handleClickOnGroupRemove = (row: DoorGroupTableRecord) => {
    setDoorGroupList(doorGroupList.map((item) => {
      if (item.doorGroup.GroupName === row.groupName) {
        return { ...item, isChecked: false };
      }
      return item;
    }));
  }

  const setDoorGroupsToDefault = () => {setDoorGroupListBasedOnForm();}

  const handleClickOnClearAll = () => {
    selectedTab === AccessTabs.GroupsTab ? setDoorGroupList(doorGroupList.map((item) => ({ ...item, isChecked: false }))) : setDisplayedRoomModels([]);
  }

  const handleDoorGroupOpen = (row: DoorGroupTableRecord) => {
    const selectedDoorGroup = props.listOfDoorGroups.find((doorGroup) => doorGroup.GroupName === row.groupName);
    if (selectedDoorGroup) {
      props.handleDoorGroupDialogOpen(selectedDoorGroup);
    } else {
      notifyError("Couldn't find a group with the given name!", "");
    }
  }

  const setDoorGroupListBasedOnForm = () => {
    const doorGroupListItems = getDoorGroupListItems();
    setDoorGroupList(doorGroupListItems);
  }

  const renderRoomsTable = () => {
    return (
      <>
        <CustomTable
          customColDataList={roomsColData} 
          customColRecords={displayedRoomModels}
          handleClickOnRowRemove={handleClickOnRoomRemove}
          resourceName={"room"}
          evenRowColor={white}
          oddRowColor={alpha(veryLightGrey, OPACITY_OF_ROWS)}
        />
      </>
    )
  }

  const renderGroupsTable = () => {
    return (
      <>
        <CustomTable
          customColDataList={groupsColData} 
          customColRecords={displayedDoorGroups}
          handleClickOnRowRemove={handleClickOnGroupRemove}
          resourceName={"group"}
          onRowClickHandler={handleDoorGroupOpen}
        />
      </>
    )
  }

  const isClearAllDisabled = (): boolean => {
    return (selectedTab === AccessTabs.GroupsTab && displayedDoorGroups.length === 0)
    || (selectedTab === AccessTabs.RoomsTab && displayedRoomModels.length === 0);
  }

  return (
    <StyledUserDialogContent
      width={684}
      height={550}
    >
      <StyledContentHolder>
        <StyledContentRowHolder style={{ alignItems: 'center' }}>
          <StyledButtonHolder>
            {buildings && <StyledButton
                buttonHeight={37}
                buttonWidth={144}
                displayedName={ADD_BUTTON_TEXT[selectedTab]}
                handleOnClick={() => (selectedTab === AccessTabs.GroupsTab ? handleClickOnAddGroups() : handleClickOnAddRooms())}
                backgroundColor={white}
                icon={addBlack}
            />}
            <StyledButton
                buttonHeight={37}
                buttonWidth={104}
                displayedName={"Clear All"}
                handleOnClick={handleClickOnClearAll}
                backgroundColor={white}
                disabled={isClearAllDisabled()}
            />
          </StyledButtonHolder>
            <TabsField 
              width={187}
              height={48}
              tabs={TABS_FIELD_OPTIONS}
              isBorder
              selectedTab={selectedTab}
              handleOnClick={(index) => setSelectedTab(index)}
            />
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <TableContainer>
            {selectedTab === AccessTabs.RoomsTab ? renderRoomsTable() : renderGroupsTable()}
          </TableContainer>
        </StyledContentRowHolder>
        <StyledContentRowHolder>
          <UserAccessDateSelectorCard
            form={props.form}
            setForm={props.setForm}
            error={props.error}
          />
        </StyledContentRowHolder>
      </StyledContentHolder>
      <BaseUserAccessDialog
          isDialogOpen={isAddRoomsDialogOpen}
          setDialogOpen={setAddRoomsDialogOpen}
          isNoActionDialog={false}
          dialogResourceName={"Rooms"}
          MainDialogComponent={
            <AddRoomsDialog
              setSelectedRooms={setSelectedRooms}
              selectedRooms={selectedRooms}
              doorGroupList={doorGroupList}
            />
          }
          handleConfirm={handleClickOnRoomsConfirm}
          selectedResourceNumber={selectedRooms.length}
        />
      <AccessGroupDialog 
        isDialogOpen={isAddGroupsDialogOpen} 
        setDialogOpen={setAddGroupsDialogOpen}
        doorGroupList={doorGroupList}
        setDoorGroupList={setDoorGroupList}
        setDoorGroupsToDefault={setDoorGroupsToDefault}
      />
    </StyledUserDialogContent>
  );
};

const MapStateToProps = (state) => {
  return { 
    buildingList: state.buildingList.buildings,
    doorGroupsList: state.doorGroupsList.doorGroups,
    roomsList: state.roomList.rooms,
  };
};

const MapDispatchToProps = (dispatch) => {
  return { fetchBuildings: (actualsite: string) => fetchAllBuildings(actualsite, dispatch) };
};

export default connect(MapStateToProps, MapDispatchToProps)(UserAccessPage);

export const StyledButtonHolder = styled.div`
    display: flex;
    gap: 15px;
`;

export const TableContainer = styled.div`
  width: 100%;
  height: 195px;
`;