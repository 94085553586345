import { useEffect } from 'react';
import { isAllFormInputsValid } from 'helper/DialogUtils';
import CardDialogFormError, { CardDetailsFormError } from 'model/CardDialogFormError';
import { useDispatch, useSelector } from 'react-redux';
import { IRootState } from 'redux/store';
import { setCardDialogFormError, setCardDialogIsValidationOpen, setCardDialogShouldConfirmationDialogSubmit } from 'redux/actions/cardDialogActions';
import { setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { CardFormError } from 'components/cards/dialogs/cardDialog/cardDialogLogic/CardDialogConsts';
import { notifyInfo } from 'helper/NotificationService';
import { selectOpenedDialog } from 'redux/selectors/userSelector';
import { DialogToOpen } from 'model/enums';
import { dateFieldValidators, numberFieldValidators, selectorFieldValidators, validate } from 'helper/CommonInputFieldValidators';
import { useIsCardFieldDisplayed } from './useIsInputDisplayed';

export const useCardDialogValidation = () => {

  const dispatch = useDispatch();
  const openedDialog = useSelector(selectOpenedDialog);
  const { form, isEdited, isValidationOpen } = useSelector((state: IRootState) => state.cardDialogStorage);
  const inputDisplayer = useIsCardFieldDisplayed();

  const geCardDetailsFormErrorModel = (): CardDetailsFormError => {
    return form
      ? {
          user: validate(selectorFieldValidators({isRequired: true}), form.cardDetails.user?.userGlobalId),
          startDate: !form.cardDetails.isUnlimitedDateChecked && openedDialog === DialogToOpen.CreateKeyCardDialog 
            ? validate(dateFieldValidators({isRequired: openedDialog === DialogToOpen.CreateKeyCardDialog}), form.cardDetails.startDate) 
            : undefined,
          startTime: !form.cardDetails.isUnlimitedDateChecked && openedDialog === DialogToOpen.CreateKeyCardDialog 
            ?  validate(dateFieldValidators({isRequired: openedDialog === DialogToOpen.CreateKeyCardDialog}), form.cardDetails.startTime) 
            : undefined,
          lastDate: !form.cardDetails.isUnlimitedDateChecked 
            ? validate(dateFieldValidators({isRequired: true}), form.cardDetails.lastDate) 
            : undefined,
          lastTime: !form.cardDetails.isUnlimitedDateChecked 
            ? validate(dateFieldValidators({isRequired: true}), form.cardDetails.lastTime) 
            : undefined,
          department: inputDisplayer.isDepartmentDisplayed 
            ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.department?.Name)
            : undefined,
          lockReleaseTime: inputDisplayer.isLockReleaseDisplayed 
            ? validate(numberFieldValidators({isRequired: true}), form.cardDetails.lockReleaseTime) 
            : undefined,
          building: inputDisplayer.isBuildingAndFloorSelectorDisplayed 
            ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.building) 
            : undefined,
          floor: inputDisplayer.isBuildingAndFloorSelectorDisplayed 
            ? validate(selectorFieldValidators({isRequired: true}), form.cardDetails.floor) 
            : undefined,
        }
      : CardFormError.INIT_CARD_DETAILS_FORM_ERROR;
  };

  const handleClickOnSubmit = () => {
    const dialogFormError: CardDialogFormError = { cardDetails: geCardDetailsFormErrorModel() };
    if (isAllFormInputsValid(dialogFormError)) {
      /**
       * TODO: In the [NSP-288] task, construct the new card model with the provided form input values.
       * Pass this newly created object to the "handleAPICall" method, that will create or edit the card.
       */
      handleAPICall();
    } else {
      handleFailedValidation(dialogFormError);
    }
  };

  const handleAPICall = () => {
    if (!isEdited) {
      switch(openedDialog) {
        case DialogToOpen.CreateKeyCardDialog:
          /**
           * TODO: In the [NSP-288] task, call the Create Key Card API call and create a new card to the user.
           * Do not forget to implement this API call in the mock mock server as well!
           */
          notifyInfo("[Create Key Card] will be implemented here.", "");
          break;
        case DialogToOpen.CreateMasterCardDialog:
          /**
           * TODO: In the [NSP-288] task, call the Master Key Card API call and create a new card to the user.
           * Do not forget to implement this API call in the mock mock server as well!
           */
          notifyInfo("[Create Master Card] will be implemented here.", "");
          break;
        case DialogToOpen.CreateFloorCardDialog:
          /**
           * TODO: In the [NSP-288] task, call the Floor Key Card API call and create a new card to the user.
           * Do not forget to implement this API call in the mock mock server as well!
           */
          notifyInfo("[Create Floor Card] will be implemented here.", "");
          break;
        case DialogToOpen.CreateEmergencyCardDialog:
          /**
           * TODO: In the [NSP-288] task, call the Emergency Key Card API call and create a new card to the user.
           * Do not forget to implement this API call in the mock mock server as well!
           */
          notifyInfo("[Create Emergency Card] will be implemented here.", "");
          break;
      }
      handleSuccessfulValidationEnd();
    } else {
      /**
       * TODO: In a later task, implement here the edition of the card.
       * The card can be Master Card, Floor Card, Key Card, and Emergency Card.
       */
      handleSuccessfulValidationEnd();
    }
  };

  const handleSuccessfulValidationEnd = () => {
    dispatch(setCardDialogFormError(CardFormError.INIT_CARD_DIALOG_FORM_ERROR));
    dispatch(setUsersIsRefreshUsersList(true));
    dispatch(setCardDialogShouldConfirmationDialogSubmit(true));
    closeValidation();
  };

  const handleFailedValidation = (dialogFormError: CardDialogFormError) => {
    dispatch(setCardDialogFormError(dialogFormError));
    closeValidation();
  }

  const closeValidation = () => {
    dispatch(setCardDialogIsValidationOpen(false));
  }

  useEffect(() => {
    if (isValidationOpen) {
      handleClickOnSubmit();
    }
  }, [isValidationOpen]);
}
