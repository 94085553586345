import React, { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { Route, Routes, Navigate } from 'react-router-dom';
import SystemDashboard from '../screens/SystemDashboard';
import SiteDashboard from '../screens/SiteDashboard';
import NotFound from '../screens/NotFound';
import NetworkError from 'components/screens/NetworkError';
import GeneralTopLoadingBar from '../GeneralTopLoadingBar';
import Header from '../Header';
import { security } from 'API/auth/Security';
import Connector from 'helper/SignalRConnection';
import { useDispatch } from 'react-redux';
import { EventMessageType } from 'model/enums';
import { PageNavigation } from 'model/enums';
import { addEventToStore } from 'redux/actions/eventActions';
import { addDoorStatusToStore } from 'redux/actions/doorStatusActions';
import { BASE_URL, REMOTE_SERVER_URL } from 'API/axios/AxiosInstance';

function NavigationProvider() {
  const {
    loginWithRedirect,
    isAuthenticated,
    isLoading,
    getAccessTokenSilently,
  } = useAuth0();
  security.setAccessTokenSilently(getAccessTokenSilently);
  const { signalrEvents } = Connector();

  const dispatch = useDispatch();
  const [location, setLocation] = useState(PageNavigation.Overview);

  useEffect(() => {
    if (BASE_URL !== REMOTE_SERVER_URL) {
      return;
    }
    signalrEvents((message) => {
      const messageObject = JSON.parse(message);
      if (messageObject.MessageType === EventMessageType.DoorEvent) {
        dispatch(addEventToStore(messageObject.Message, messageObject.SiteName));
      }
      if (messageObject.MessageType === EventMessageType.DoorStatus) {
        dispatch(addDoorStatusToStore(messageObject.Message));
      }
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading, isAuthenticated]);

  const handleLocation = (location: any) => {
    setLocation(location);
  };

  return !isLoading && isAuthenticated ? (
    <>
      <Header handleLocation={handleLocation} />
      <Routes>
        <Route path="/" element={<SystemDashboard />} />
        <Route path="/site/:id" element={<SiteDashboard location={location}/>} />
        <Route path="/404" element={<NotFound />} />
        <Route path="/networkerror" element={<NetworkError />} />
        <Route path="*" element={<Navigate to="/404" replace />} />
      </Routes>
    </>
  ) : (
    <GeneralTopLoadingBar />
  );
}
export default NavigationProvider;
