import * as React from 'react';
import { BluetoothForm } from 'model/UserDialogForm';
import { BluetoothError } from 'model/UserDialogFormError';
import { StyledContentRowHolder, StyledUserDialogContent } from '../UserDialogCommonStyle';
import styled from 'styled-components';
import { blue, lightBlue, listItemLightGray, red, veryLightGrey, white } from 'constants/theme';
import { ReactComponent as InfoCircle} from 'media/info-circle.svg';
import deleteCard from 'media/deleteCard.svg';
import changeIcon from 'media/change.svg';
import suspendIcon from 'media/suspend.svg';
import phoneIcon from 'media/phone.svg';
import { notifyInfo } from 'helper/NotificationService';
import { BluetoothMenuItems, BluetoothMenuItemDescriptions, UserDialogBluetoothFormText, UserDialogFormBluetoothFormPlaceHolderText, BluetoothUserStatuses, BluetoothSuspendedUserInfo, BluetoothDeletedUserInfo, BluetoothMenuDialogs } from 'model/enums';
import MultiTypeInputField from 'components/controls/inputs/MultiTypeInputField';
import SelectField from 'components/controls/selects/SelectField';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import { restrictedKeyCodes } from 'constants/RestrictedKeyCodes';
import NumberInputField from 'components/controls/inputs/NumberInputField';
import { LIST_OF_MOBILE_USER_TYPES, PHONE_COUNTRY_CODE_LIST } from 'constants/arrays';
import { StyledMenuItem, StyledSvg } from '../contentLeftSide/UserImage';
import { Menu } from '@mui/material';
import { CardActionImage } from 'components/cards/cardActions/CardActionsCommonStyle';
import { StyledFont } from './accessPageDialogs/AddGroupsDialog';
import DialogTemplate from '../../DialogTemplate';
import { ACTIVATE_USER_DIALOG_TEXT, CHANGE_MOBILE_DIALOG_TEXT, DELETE_USER_DIALOG_TEXT, RE_ACTIVATE_USER_DIALOG_TEXT, SMS_SENT_OUT_DIALOG_TEXT, SUSPEND_USER_DIALOG_TEXT } from 'constants/dialogTexts';
import DialogTexts from 'model/DialogTexts';
import WritableSelectField from 'components/controls/selects/WritableSelectField';
import { NumberLimitsUtil } from 'helper/NumberLimitsUtil';
import { isInfoCircleDisplayed } from '../UserDialogConsts';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import UserTableRecord from 'model/UserTableRecord';
import bluetoothIcon from 'media/bluetoothIcon.svg';
import StyledButton from 'components/controls/button/StyledButton';

type Props = {
  error: BluetoothError;
  form: BluetoothForm;
  isEdited: boolean;
  userTableRecord?: UserTableRecord;
  initBluetoothForm: BluetoothForm;
  setForm: (form: BluetoothForm) => void;
};

const UserBluetoothPage = ({
  error,
  form,
  isEdited,
  userTableRecord,
  initBluetoothForm,
  setForm,
} :Props) => {

    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isDialogTemplateOpen, setDialogTemplateOpen] = React.useState<boolean>(false);
    const [dialogTemplateSubmitColor, setDialogTemplateSubmitColor] = React.useState<string>(blue);
    const [isDialogTemplateSubmitHidden, setDialogTemplateSubmitHidden] = React.useState<boolean>(false);
    const [dialogId, setDialogId] = React.useState<string>(BluetoothMenuItems.ChangeMobile);
    const [dialogTemplateText, setDialogTemplateText] = React.useState<DialogTexts>(CHANGE_MOBILE_DIALOG_TEXT);
    const IS_INFO_CIRCLE_DISPLAYED = isInfoCircleDisplayed(form.userStatus);
    const editingUser: UserModel | undefined = useSelector(selectUser(userTableRecord?.globalId));

    const open = Boolean(anchorEl);
    const BLUETOOTH_MENU_ITEMS = Object.values(BluetoothMenuItems);
    const BLUETOOTH_MENU_ITEM_DESCRIPTIONS = Object.values(BluetoothMenuItemDescriptions);

    const getMenuItemIcon = (menuItem: BluetoothMenuItems) => {
      switch(menuItem) {
        case BluetoothMenuItems.ChangeMobile:
          return phoneIcon;
        case BluetoothMenuItems.ReSend:
          return changeIcon;
        case BluetoothMenuItems.Suspend:
          return suspendIcon;
        case BluetoothMenuItems.Delete:
          return deleteCard;
      }
    }

    const handleChangeMobile = () => {
      setDialogTemplateText(CHANGE_MOBILE_DIALOG_TEXT);
      setDialogId(BluetoothMenuDialogs.ChangeMobile);
      setDialogTemplateSubmitColor(red);
      setDialogTemplateSubmitHidden(false);
    }

    const handleReSend = () => {
      //TODO: In a later task, implement the Re-Send business logic once the backend service is available.
      setDialogTemplateText(SMS_SENT_OUT_DIALOG_TEXT);
      setDialogId(BluetoothMenuDialogs.ReSend);
      setDialogTemplateSubmitHidden(true);
    }

    const handleSuspend = () => {
      setDialogTemplateText(SUSPEND_USER_DIALOG_TEXT);
      setDialogId(BluetoothMenuDialogs.Suspend);
      setDialogTemplateSubmitHidden(false);
      setDialogTemplateSubmitColor(red);
    }

    const handleDelete = () => {
      setDialogTemplateText(DELETE_USER_DIALOG_TEXT);
      setDialogId(BluetoothMenuDialogs.Delete);
      setDialogTemplateSubmitHidden(false);
      setDialogTemplateSubmitColor(red);
    }

    const handleActivateUser = () => {
      setDialogTemplateText(ACTIVATE_USER_DIALOG_TEXT);
      setDialogId(BluetoothMenuDialogs.Suspended);
      setDialogTemplateSubmitHidden(false);
      setDialogTemplateSubmitColor(blue);
    }

    const handleReActivateUser = () => {
      setDialogTemplateText(RE_ACTIVATE_USER_DIALOG_TEXT);
      setDialogId(BluetoothMenuDialogs.Deleted);
      setDialogTemplateSubmitHidden(false);
      setDialogTemplateSubmitColor(blue);
    }

    const handleOptionOnClick = (menuItem: BluetoothMenuItems) => {
      const handler = Object.freeze({
        [BluetoothMenuItems.ChangeMobile]: () => handleChangeMobile(),
        [BluetoothMenuItems.ReSend]: () => handleReSend(),
        [BluetoothMenuItems.Suspend]: () => handleSuspend(),
        [BluetoothMenuItems.Delete]: () => handleDelete(),
      });
      handler[menuItem.toString()].call();
      handleDialogTemplateWindow(true);
    }

    const handleChangeMobileSubmit = () => {
      //TODO: In a later task, implement the Mobile Change business logic once the backend service is available.
      notifyInfo("[Deactivate Mobile] clicked!", "");
    }

    const handleChangeSuspendSubmit = () => {
      //TODO: In a later task, implement the Suspend User business logic once the backend service is available.
      notifyInfo("[Suspend User] clicked!", "");
      setForm({...form, userStatus: BluetoothUserStatuses.Suspended});
    }

    const handleDeleteSubmit = () => {
      //TODO: In a later task, implement the Delete User business logic once the backend service is available.
      notifyInfo("[Delete User] clicked!", "");
      setForm({...form, userStatus: BluetoothUserStatuses.Deleted});
    }

    const handleActivateSubmit = () => {
      //TODO: In a later task, implement the Activate User business logic once the backend service is available.
      notifyInfo("[Activate User] clicked!", "");
      setForm({...form, userStatus: undefined});
    }

    const handleReActivateSubmit = () => {
      //TODO: In a later task, implement the Re-Activate User business logic once the backend service is available.
      notifyInfo("[Re-Activate User] clicked!", "");
      setForm({...form, userStatus: undefined});
    }

    const handleDialogTemplateWindow = (isDialogOpen: boolean) => {
      setDialogTemplateOpen(isDialogOpen);
      setAnchorEl(null);
    }

    const handleOptionOnSubmit = (menuItem: string) => {
      const handler = Object.freeze({
        [BluetoothMenuDialogs.ChangeMobile]: () => handleChangeMobileSubmit(),
        [BluetoothMenuDialogs.Suspend]: () => handleChangeSuspendSubmit(),
        [BluetoothMenuDialogs.Delete]: () => handleDeleteSubmit(),
        [BluetoothMenuDialogs.Suspended]: () => handleActivateSubmit(),
        [BluetoothMenuDialogs.Deleted]: () => handleReActivateSubmit(),
      });
      handler[menuItem].call();
      handleDialogTemplateWindow(false);
    }

    const getInfoMessage = (): string => {
      /**
       * TODO:
       * Finish info message retrieval logic once
       * the backend service is available
       * and the other subtask is ready.
       */
      switch(form.userStatus) {
        case BluetoothUserStatuses.Suspended:
          return BluetoothSuspendedUserInfo.Message;
        case BluetoothUserStatuses.Deleted:
          return BluetoothDeletedUserInfo.Message;
        default:
          return ``;
      }
    };

    const getInfoMessageAction = (): string => {
      /**
       * TODO:
       * Finish info message action retrieval logic once
       * the backend service is available
       * and the other subtask is ready.
       */
      switch(form.userStatus) {
        case BluetoothUserStatuses.Suspended:
          return BluetoothSuspendedUserInfo.Action;
        case BluetoothUserStatuses.Deleted:
          return BluetoothDeletedUserInfo.Action;
        default:
          return ``;
      }
    }

    const handleUnderlinedElementOnClick = () => {
      const handler = Object.freeze({
        [BluetoothUserStatuses.Suspended]: () => handleActivateUser(),
        [BluetoothUserStatuses.Deleted]: () => handleReActivateUser(),
      });
      if (form.userStatus) {
        handler[form.userStatus.toString()].call();
        handleDialogTemplateWindow(true);
      }
    }

    const isMenuOptionHidden = (item: BluetoothMenuItems) => {
      return (item === BluetoothMenuItems.Suspend && form.userStatus === BluetoothUserStatuses.Suspended)
        || (item === BluetoothMenuItems.Delete && form.userStatus === BluetoothUserStatuses.Deleted);
    }

    const isBluetoothHeaderDisplayed = () => {
      return isEdited && editingUser && !!editingUser.BleMobileNumber;
    }

    return (
            <StyledUserDialogContent
              width={684}
              height={460}
            >
              <CustomContentRowHolder
                $isInfoTextDisplayed={IS_INFO_CIRCLE_DISPLAYED}
                $isHidden={isBluetoothHeaderDisplayed()}
              >
                <InfoContainer
                  $isInfoTextDisplayed={IS_INFO_CIRCLE_DISPLAYED}
                >
                  <InfoCircle/>
                  <span>
                    <InfoMessage>{`${getInfoMessage()} `}</InfoMessage>
                    <UnderlinedElement onClick={() => handleUnderlinedElementOnClick()}>{getInfoMessageAction()}</UnderlinedElement>
                  </span>
                </InfoContainer>
                <div>
                  {isBluetoothHeaderDisplayed() &&
                    <StyledButton
                      buttonHeight={40}
                      buttonWidth={199}
                      displayedName="Bluetooth Actions"
                      handleOnClick={e => setAnchorEl(e.currentTarget)}
                      backgroundColor={white}
                      icon={bluetoothIcon}
                      isIconPositionedAtStart
                    />
                  }
                  <Menu
                    id="menu"
                    anchorEl={anchorEl}
                    open={open}
                    onClose={() => setAnchorEl(null)}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'right',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'right',
                    }}
                    disableScrollLock={true}
                    sx={{ top: "10px" }}
                  >
                    { BLUETOOTH_MENU_ITEMS.map((item, index) => (
                      <div
                        key={index}
                        hidden={isMenuOptionHidden(item)}
                      >
                        <StyledMenuItem
                          key={index}
                          onClick={() => handleOptionOnClick(item)}
                          sx={{ borderTop: `2px solid ${veryLightGrey}`, height: '69px' }}
                        >
                          <StyledSvg>
                            <CardActionImage src={getMenuItemIcon(item)} alt={item}/>
                          </StyledSvg>
                          <div>
                            <StyledFont
                              $fontSize={"14px"}
                              $lineHeight={"24.35px"}
                            >
                              {item}
                            </StyledFont>
                            <StyledFont
                              $fontSize={"14px"}
                              $fontColor={listItemLightGray}
                            >
                              {BLUETOOTH_MENU_ITEM_DESCRIPTIONS[index]}
                            </StyledFont>
                          </div>
                        </StyledMenuItem>
                      </div>
                    )) }
                  </Menu>
                </div>
              </CustomContentRowHolder>
              <UpperContainer>
                <StyledContentRowHolder>
                  <MultiTypeInputField
                    inputFieldWidth={"330px"}
                    inputFieldHeight={"40px"}
                    displayedName={UserDialogBluetoothFormText.Username}
                    placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.Username}
                    setValue={(username) => setForm({...form, username})}
                    inputValue={form.username}
                    invalidFormMessage={form.username === initBluetoothForm.username ? undefined : error.usernameError}
                    inputProps={{maxLength: NumberLimitsUtil.INPUT_TEXT_CHARACTER_MAX_LENGTH}}
                    isDisabled={isEdited}
                  />
                  <MultiTypeInputField
                    inputFieldWidth={"329px"}
                    inputFieldHeight={"40px"}
                    displayedName={UserDialogBluetoothFormText.Email}
                    placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.Email}
                    setValue={(email) => setForm({...form, email})}
                    inputValue={form.email}
                    invalidFormMessage={form.email === initBluetoothForm.email ? undefined : error.emailError}
                    inputProps={{maxLength: NumberLimitsUtil.INPUT_TEXT_CHARACTER_MAX_LENGTH}}
                    inputType='email'
                  />
                </StyledContentRowHolder>
                <StyledContentRowHolder>
                  <WritableSelectField
                      inputFieldHeight={"40px"}
                      inputFieldWidth={"80px"}
                      displayedName={UserDialogBluetoothFormText.MobileNumber}
                      placeholderInput={PHONE_COUNTRY_CODE_LIST[0]}
                      setValue={(mobileCountryCode) => setForm({...form, mobileCountryCode})}
                      inputValue={form.mobileCountryCode}
                      selectOptions={PHONE_COUNTRY_CODE_LIST}
                  />
                  <NumberInputField 
                    inputFieldWidth={"230px"}
                    inputFieldHeight={"40px"}
                    placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.MobileNumber}
                    inputValue={form.mobileNumber}
                    setValue={(mobileNumber) => setForm({...form, mobileNumber})}
                    invalidFormMessage={
                      form.mobileCountryCode === initBluetoothForm.mobileCountryCode 
                        && form.mobileNumber === initBluetoothForm.mobileNumber 
                        ? undefined 
                        : error.mobileNumberError
                    }
                    restrictKeysToPress={restrictedKeyCodes}
                    isStepperDisplayed={false}
                    inputProps={{min: 0, max: NumberLimitsUtil.INT32_UPPER_LIMIT, maxLength: NumberLimitsUtil.PHONE_NUMBER_UPPER_LIMIT}}
                  />
                  <SelectField
                    inputFieldHeight={"40px"}
                    inputFieldWidth={"329px"}
                    displayedName={UserDialogBluetoothFormText.MobileUser}
                    selectOptions={LIST_OF_MOBILE_USER_TYPES}
                    setValue={(mobileUser) => setForm({...form, mobileUser})}
                    inputValue={LIST_OF_MOBILE_USER_TYPES.find(type => type === form.mobileUser) ?? LIST_OF_MOBILE_USER_TYPES[0]}
                  />
                </StyledContentRowHolder>
              </UpperContainer>
              <LowerContainer>
                <CheckboxField
                  boxWidth={"100%"}
                  boxHeight={"40px"}
                  displayedName={UserDialogBluetoothFormText.RemoteOpenDoors}
                  setValue={(remoteOpenDoors) => setForm({...form, remoteOpenDoors})}
                  inputValue={form.remoteOpenDoors}
                />
                <CheckboxField
                  boxWidth={"100%"}
                  boxHeight={"40px"}
                  displayedName={UserDialogBluetoothFormText.TemporaryAccess}
                  setValue={(temporaryAccess) => setForm({...form, temporaryAccess})}
                  inputValue={form.temporaryAccess}
                />
                <CheckboxField
                  boxWidth={"100%"}
                  boxHeight={"40px"}
                  displayedName={UserDialogBluetoothFormText.MeetingRoom}
                  setValue={(meetingRoom) => setForm({...form, meetingRoom})}
                  inputValue={form.meetingRoom}
                />
                <NumberInputField
                  inputFieldHeight={"40px"}
                  inputFieldWidth={"100%"}
                  displayedName={UserDialogBluetoothFormText.LockReleaseTime}
                  placeholderInput={UserDialogFormBluetoothFormPlaceHolderText.LockReleaseTime}
                  inputValue={form.lockReleaseTime}
                  setValue={(lockReleaseTime) => setForm({...form, lockReleaseTime})}
                  invalidFormMessage={error.lockReleaseTimeError}
                  restrictKeysToPress={restrictedKeyCodes}
                  inputProps={{min: 0, max: NumberLimitsUtil.UBYTE_UPPER_LIMIT, maxLength: NumberLimitsUtil.UBYTE_UPPER_LIMIT_MAX_LENGTH}}
                />
              </LowerContainer>
              <DialogTemplate
                  handleSubmit={(dialogId) => handleOptionOnSubmit(dialogId)}
                  handleClose={() => handleDialogTemplateWindow(false)}
                  open={isDialogTemplateOpen}
                  dialogText={dialogTemplateText}
                  submitButtonColor={dialogTemplateSubmitColor}
                  isSubmitButtonHidden={isDialogTemplateSubmitHidden}
                  dialogId={dialogId}
              />
            </StyledUserDialogContent>
    );
};

export default UserBluetoothPage;

const UpperContainer = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 20px;
    gap: 26px;
`;

const LowerContainer = styled.div`
    width: 330px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 14px;
`;

const CustomContentRowHolder = styled(StyledContentRowHolder)<{ 
  $isInfoTextDisplayed?: boolean,
  $isHidden?: boolean,
}>((props) => ({
  justifyContent: props.$isInfoTextDisplayed ? 'space-between' : 'end',
  display: props.$isHidden ? 'flex' : 'none',
  marginBottom: "24px",
}));


const InfoContainer = styled.div<{ $isInfoTextDisplayed?: boolean }>((props) => ({
  display: props.$isInfoTextDisplayed ? 'flex' : 'none',
  gap: '6px',
  alignItems: 'center',
  padding: '8px 12px',
  backgroundColor: lightBlue,
  color: blue,
  borderRadius: '8px',
  fontSize: '14px',
}));

const InfoMessage = styled.span`
  font-weight: 400;
`;

const UnderlinedElement = styled.u`
  font-weight: 500;
  cursor: pointer;
`;