import * as React from 'react';
import { DataGrid, GridColDef, gridClasses } from '@mui/x-data-grid';
import styled from 'styled-components';
import { black, lightBlue, listItemLightGray, mainBlue, OPACITY_OF_ROWS, veryLightGrey, white } from 'constants/theme';
import { PageNavigation, SortOrder } from 'model/enums';
import SortModel from 'model/SortModel';
import { useState } from 'react';
import { ReactComponent as Sort} from 'media/sort.svg';
import { alpha } from '@mui/material';
import UserTableRecord from 'model/UserTableRecord';
import { customScrollBarCSSProperties } from 'constants/globalStyle';
import { CARD_LIMITED_DATE_TEXT, CARD_TYPE_TEXT, isFieldEqualToSpecialHeaderNames, SELECTIONS_HEADER_NAME, THREE_DOTS_HEADER_NAME, UNLIMITED_DATE_TEXT } from 'helper/DataTableUtils';
import { getCardTextByCardStatus } from 'constants/cardIcons';
import CardCheckboxHeaderCell from 'components/cards/dataGridTableCells/CardCheckboxHeaderCell';
import CardBinHeaderCell from 'components/cards/dataGridTableCells/CardBinHeaderCell';

type Props = {
  userTableRecordRows: UserTableRecord[];
  columns: GridColDef[];
  initialSort: SortModel;
  paginationSize: number;
  isRowSelected: boolean;
  height?: string;
  isFooterDisplayed?: boolean;
  pageNavigation: PageNavigation;
  setSelectedUserTableRecordRow: (row: UserTableRecord) => void;
};

type StyledSvgProps = {
  color?: string;
};

const DataTableBase = ({
  userTableRecordRows,
  columns,
  initialSort,
  paginationSize,
  isRowSelected,
  height,
  isFooterDisplayed,
  pageNavigation,
  setSelectedUserTableRecordRow,
}: Props) => {

  const [sortOrder, setSortOrder] = useState<SortModel>(initialSort);

  const displayHeaderTitle = (field: string, headerName: string) => {
    const isActive = field === sortOrder.field;

    return ( !isFieldEqualToSpecialHeaderNames(field) ?
      <HeaderCellContent
        color={ isActive ? mainBlue : black }
        onClick={() => handleSortClick(field)}
      >
        {headerName}
        <StyledSvg color={isActive ? mainBlue : black } >
          <Sort />
        </StyledSvg>
      </HeaderCellContent> 
    : null )
  }

  const displayHeaderBinIcon = (field: string) => (
    field === THREE_DOTS_HEADER_NAME && pageNavigation === PageNavigation.ManageUsers ? 
      CardBinHeaderCell()
    : null
  )

  const displayHeaderCheckboxIcon = (field: string) => (
    field === SELECTIONS_HEADER_NAME && pageNavigation === PageNavigation.ManageUsers ? 
      CardCheckboxHeaderCell()
   : null
 );

  const CustomHeader = (params: any) => {
    const colDef = params.colDef;

    return (
      <React.Fragment>
        { displayHeaderTitle(colDef.field, colDef.headerName) }
        { displayHeaderBinIcon(colDef.field) }
        { displayHeaderCheckboxIcon(colDef.field) }
      </React.Fragment>
    );
  };

  const handleSortClick = (field: string) => {
    setSortOrder(new SortModel(field, sortOrder.sort === SortOrder.Asc ? SortOrder.Desc : SortOrder.Asc))
  }

  const handleRowClick = (event) => {
    setSelectedUserTableRecordRow({...event.row.original, ...event.row.id});
  }

  const handleRowModificationByPageNavigation = (row: UserTableRecord) =>{
    return {
      ...row,
      limitedDate: pageNavigation === PageNavigation.ManageCards && !row.limitedDate 
        ? (row.cardId ? UNLIMITED_DATE_TEXT : CARD_LIMITED_DATE_TEXT)
        : row.limitedDate, 
      cardType: pageNavigation === PageNavigation.ManageCards && !row.cardType 
        ? CARD_TYPE_TEXT 
        : row.cardType,
      cardInUse: getCardTextByCardStatus(row.cardInUse),
    };
  }

  return (
    <StyledDataGrid
        rows={userTableRecordRows.map((row, index) => ({ ...handleRowModificationByPageNavigation(row), id: index, original: row}))}
        columns={columns.map((col) => ({
          ...col,
          renderHeader: (params: any) => <CustomHeader {...params} />,
        }))}
        height={height}
        initialState={{ pagination: { paginationModel: { pageSize: paginationSize } }, }}
        sortModel={[{ field: sortOrder.field, sort: sortOrder.sort }]}
        pageSizeOptions={[paginationSize]}
        disableMultipleRowSelection
        sx={{borderColor: 'transparent'}}
        onRowClick={handleRowClick}
        getRowClassName={(params) => params.indexRelativeToCurrentPage % 2 === 0 ? 'even' : 'odd'}
        $isRowSelected={isRowSelected}
        $isFooterDisplayed={isFooterDisplayed}
      />
  );
};

export const StyledDataGrid = styled(DataGrid)<{
  height?: string,
  $isRowSelected?: boolean,
  $isFooterDisplayed?: boolean,
  $oddRowColor?: string,
}>((props) => ({
  height: props.height ? `${props.height}` : `40vw`, 
  width: '100%',
  '--DataGrid-rowBorderColor': 'transparent',
  '& .MuiDataGrid-root': { borderColor: 'transparent !important', border: 'none', breakInside: 'avoid','--rowBorderColor': 'transparent' },
  '& .MuiDataGrid-columnHeader': {
    backgroundColor: `${white} !important`,
    color: black,
    fontSize: '1rem',
    fontWeight: 'bold',
  },
  '& .MuiDataGrid-scrollbar' : customScrollBarCSSProperties(),
  '& .MuiDataGrid-cell': {
    color: listItemLightGray,
    fontSize: '14px',
    zIndex: 3,
    borderBottom: 'none',
  },
  '& .MuiDataGrid-cell:first-of-type': {
    borderTopLeftRadius: '10px',
    borderBottomLeftRadius: '10px',
  },
  '& .MuiDataGrid-cell:last-of-type': {
    borderTopRightRadius: '10px',
    borderBottomRightRadius: '10px',
  },
  '& .MuiDataGrid-withBorder': {border: 'none', borderColor: 'transparent !important'},
  '& .MuiDataGrid-footerContainer': {border: 'none', display: (props.$isFooterDisplayed ? 'flow' : 'none')},
  '& .MuiDataGrid-row': {
    zIndex: 3, 
    borderRadius: '10px', 
    borderBottom: 'none', 
    breakInside: 'avoid',
    '--rowBorderColor': 'transparent',
  },
  '& .MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus': {outline: 'none'},
  '& .MuiDataGrid-columnHeader:focus-within' : {outline: 'none'},
  '& .MuiDataGrid-cell:focus-within, & .MuiDataGrid-cell:focus': {outline: "none"},
  '& .MuiDataGrid-cellEmpty, .MuiDataGrid-columnSeparator, .MuiDataGrid-iconButtonContainer': {display: 'none'},
  '& .MuiDataGrid-overlayWrapper' : {height : '30px'},
  '& .MuiDataGrid-selectedRowCount' : {display: 'none'},
  [`& .${gridClasses.row}.even`]: {
    backgroundColor: white,
    '&:hover': {
      backgroundColor: alpha(veryLightGrey, OPACITY_OF_ROWS),
      '@media (hover: none)': { backgroundColor: 'transparent'},
    },
    '&.Mui-selected': {
      backgroundColor: (props.$isRowSelected ? lightBlue : white),
      '&:hover': {
        backgroundColor: (props.$isRowSelected ? alpha(lightBlue, OPACITY_OF_ROWS*2) : white),
        '@media (hover: none)': { backgroundColor: 'transparent'},
      },
    },
  },
  [`& .${gridClasses.row}.odd`]: {
    backgroundColor: (props.$oddRowColor ?? veryLightGrey),
    '&:hover': {
      backgroundColor: alpha(veryLightGrey, OPACITY_OF_ROWS),
      '@media (hover: none)': { backgroundColor: 'transparent'},
    },
    '&.Mui-selected': {
      backgroundColor: (props.$isRowSelected ? lightBlue : veryLightGrey),
      '&:hover': {
        backgroundColor: (props.$isRowSelected ? alpha(lightBlue, OPACITY_OF_ROWS*2) : veryLightGrey),
        '@media (hover: none)': { backgroundColor: 'transparent'},
      },
    },
  },
}));

export const HeaderCellContent = styled.div<{ cursor?: string, $fontWeight?: number, $fontSize?: number}>((props) => ({
  fontWeight: (props.$fontWeight ? `${props.$fontWeight} !important` : 'bold'),
  fontSize: (props.$fontSize ? `${props.$fontSize}px` : 'unset'),
  display: 'flex',
  gap: '8px',
  cursor: (props.cursor ?? 'pointer'),
  color: props.color,
  alignItems: 'center',
  textWrap: 'wrap'
}));

export const StyledSvg = styled.div<StyledSvgProps>`
  svg {
    fill: ${props => props.color};
  }
`;

export default DataTableBase;
