import * as React from 'react';
import { blue, red } from 'constants/theme';
import deleteUserIconWhite from 'media/deleteUserBinWhite.svg';
import deleteUserIconBlack from 'media/deleteUserBinBlack.svg';
import StyledButton from 'components/controls/button/StyledButton';
import DialogTemplate from '../dialogs/DialogTemplate';
import { bulkDeleteUsersContent } from 'constants/dialogTexts';
import { useSelector } from 'react-redux';
import { selectUserListStorage } from 'redux/selectors/userSelector';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { deleteUser } from 'API/commands/UserCommands';
import { useDispatch } from 'react-redux';
import { setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { notifyError, notifySuccess } from 'helper/NotificationService';
import { isBluetoothCredentialExist, isCardInUse} from '../dialogs/DialogGroup';

const CardBinHeaderCell = () => {
  const [isDialogTemplateOpen, setDialogTemplateOpen] = React.useState(false);
  const { userModels, usersToDelete } = useSelector(selectUserListStorage);
  const filteredUsersToDelete = usersToDelete.filter((user) => user.isCheckboxChecked);
  const isDisabled = filteredUsersToDelete.length === 0;
  const actualSite = ActualSiteLocator();
  const dispatch = useDispatch();

  const handleUsersDeletion = () => {
    let notDeletedUsersCount = 0;
    usersToDelete.filter(user => user.isCheckboxChecked)
      .forEach(async (user) => {
        const userModel = userModels.find((userModel) => userModel.Id === user.userId);
        if (!(isCardInUse(userModel) || isBluetoothCredentialExist(userModel))) {
          await deleteUser(actualSite, userModel?.Id, false);
        } else {
          notDeletedUsersCount++;
        }
    });
    notifyAboutDeletionMessages(notDeletedUsersCount);
  };

  const notifyAboutDeletionMessages = (notDeletedUsersCount: number) => {
    const numberOfUsersToDelete = filteredUsersToDelete.length - notDeletedUsersCount;
    if (numberOfUsersToDelete > 0)
      notifySuccess(
        `(${numberOfUsersToDelete}) 
        user${
          numberOfUsersToDelete > 1 ? `s are ` : ` is `
        }
        successfully deleted`,``);

    if (notDeletedUsersCount > 0)
      notifyError(
        `(${notDeletedUsersCount}) 
        user${notDeletedUsersCount > 1 ? `s` : ``} 
        did not get deleted`,``);

    dispatch(setUsersIsRefreshUsersList(true));
    setDialogTemplateOpen(false);
  };

  return (
    <>
      <StyledButton
        buttonHeight={32}
        buttonWidth={40}
        handleOnClick={() => setDialogTemplateOpen(true)}
        backgroundColor={blue}
        icon={isDisabled ? deleteUserIconBlack : deleteUserIconWhite}
        disabled={isDisabled}
        isFilledButton
      />
      <DialogTemplate
        handleSubmit={() => handleUsersDeletion()}
        handleClose={() => setDialogTemplateOpen(false)}
        open={isDialogTemplateOpen}
        dialogText={bulkDeleteUsersContent(filteredUsersToDelete.length)}
        submitButtonColor={red}
        dialogId="Bulk Delete"
      />
    </>
  );
};

export default CardBinHeaderCell;
