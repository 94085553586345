import * as React from 'react';
import { CustomColData } from 'model/CustomColData';
import { TestGroupData } from 'model/Floor';
import { TestRoomData } from 'model/Room';
import styled from 'styled-components';
import { listItemLightGray, white } from 'constants/theme';
import remove from 'media/remove.svg';
import { CustomizableDiv } from './dialogs/userCardDialog/UserDialogCommonStyle';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import RoomTableRecords from 'model/RoomTableRecords';
import { LightTooltip } from 'helper/Util';
import { customScrollBarCSSProperties } from 'constants/globalStyle';

type Props = {
    customColDataList: CustomColData[];
    customColRecords: any;
    resourceName: string;
    evenRowColor?: string;
    oddRowColor?: string;
    tableHeight?: string;
    handleClickOnRowRemove?: (row: any) => void;
    handleClickOnRowCheck?: (row: any) => void;
    handleClickOnAllRowCheck?: (row: any) => void;
    checkRowCheckValue?: (row: any) => boolean;
    checkIfEveryRowIsSelected?: () => boolean;
    checkIfSelectAllIsIndeterminate?: () => boolean;
    onRowClickHandler?: (row: any) => void;
};

const CustomTable = ({
    customColDataList,
    customColRecords,
    resourceName,
    evenRowColor,
    oddRowColor,
    tableHeight,
    handleClickOnRowRemove,
    handleClickOnRowCheck,
    handleClickOnAllRowCheck,
    checkRowCheckValue,
    checkIfEveryRowIsSelected,
    checkIfSelectAllIsIndeterminate,
    onRowClickHandler,
}: Props) => {
    const SHOULD_HAVE_CHECK_COLUMN = handleClickOnRowCheck && handleClickOnAllRowCheck && checkRowCheckValue && checkIfEveryRowIsSelected && checkIfSelectAllIsIndeterminate;
    const SELECT_DIV_WIDTH = SHOULD_HAVE_CHECK_COLUMN ? 10 : 0;
    const REMOVE_DIV_WIDTH = handleClickOnRowRemove ? 10 : 0;
    const FULL_TABLE_BODY_WIDTH_PERCENT = 100 - (REMOVE_DIV_WIDTH + SELECT_DIV_WIDTH);
    const customColDataListLength = customColDataList.length;

    const renderData = (data: TestRoomData[] | TestGroupData[] | RoomTableRecords[]) => {
        return data.length > 0 ? (
          <>
          {data.map((row, rowIndex) => (
            <CustomBgTableRow 
              key={rowIndex} 
              $evenRowColor={evenRowColor} 
              $oddRowColor={oddRowColor}
              cursor={onRowClickHandler ? 'pointer' : 'auto'}
              {...(onRowClickHandler ? { onClick: () => onRowClickHandler(row) } : {})}
            >
            {SHOULD_HAVE_CHECK_COLUMN && (
              <CustomizableDiv width={SELECT_DIV_WIDTH} display={"flex"} $justifyContent={"center"}>
                <CheckboxField
                      boxWidth={"100%"}
                      boxHeight={"100%"}
                      textAlign={'center'}
                      setValue={() => handleClickOnRowCheck(row)}
                      inputValue={checkRowCheckValue(row)}
                  />
              </CustomizableDiv>
            )}
              {customColDataList.map((item, colIndex) => {
                const value = item.dataFormatter ? item.dataFormatter(row[item.key as keyof typeof row]) : row[item.key as keyof typeof row];
                return (
                  <CustomizableDiv width={FULL_TABLE_BODY_WIDTH_PERCENT / customColDataListLength} key={colIndex} color={item.color} align={item.align}>
                    <EllipsisDiv title={value}>
                      {value}
                    </EllipsisDiv>
                  </CustomizableDiv>
                );
              })}
              {handleClickOnRowRemove && (
                <CustomizableDiv width={REMOVE_DIV_WIDTH} display={"flex"} $justifyContent={"center"}>
                  <LightTooltip 
                    title={`Remove ${resourceName}`} 
                    placement="top-end"
                  >
                    <RemoveImg
                      src={remove}
                      alt="Remove icon"
                      onClick={(e) => {
                        e.stopPropagation(); 
                        handleClickOnRowRemove(row);
                      }} 
                    />
                  </LightTooltip>
                </CustomizableDiv>
              )}
            </CustomBgTableRow>
          ))}
        </>
        ) : (
          <CustomizableDiv color={`${listItemLightGray}`} padding={SHOULD_HAVE_CHECK_COLUMN ? '0 0 0 20px' : '0 0 0 10px'}>There are no assigned {resourceName}s yet.</CustomizableDiv>
        );
      };
    return (
        <>
            <TableHeader>
              {SHOULD_HAVE_CHECK_COLUMN && (<CustomizableDiv width={SELECT_DIV_WIDTH} display={"flex"} $justifyContent={"center"} $alignItems={"center"}>
                <CheckboxField
                      boxWidth={"100%"}
                      boxHeight={"100%"}
                      textAlign={'center'}
                      setValue={handleClickOnAllRowCheck}
                      inputValue={checkIfEveryRowIsSelected()}
                      isIndeterminate={checkIfSelectAllIsIndeterminate()}
                  />
              </CustomizableDiv>)}
                 {customColDataList.map((item, index) => (
                     <CustomizableDiv
                       width={FULL_TABLE_BODY_WIDTH_PERCENT / customColDataListLength}
                       key={index}
                       align={item.align}
                       $justifyContent={item.align}
                       display={"flex"}
                       $alignItems={"center"}
                       $fontWeight={item.headerWeight}
                     >
                         {item.header}
                     </CustomizableDiv>
                 ))}
                 {handleClickOnRowRemove && <CustomizableDiv width={REMOVE_DIV_WIDTH}/>}
            </TableHeader>
            <TableBodyContainer height={tableHeight}>
                {renderData(customColRecords)}
            </TableBodyContainer>
        </>
    );
};

export default CustomTable;

const CustomBgTableRow = styled.div<{ $oddRowColor?: string; $evenRowColor?: string, cursor?: string }>((props) => ({
  padding: '8px 12px',
  display: 'flex',
  borderRadius: '10px',
  '&:nth-child(odd)': { backgroundColor: props.$oddRowColor || white },
  '&:nth-child(even)': {backgroundColor: props.$evenRowColor || white },
  cursor: props.cursor,
}));
  
const TableHeader = styled.div`
  padding: 8px 20px 8px 12px;
  display: flex;
  justify-content: space-between;
`;

const TableBodyContainer = styled.div<{ height?: string }>((props) => ({
  height: props.height ?? "90%",
  ...customScrollBarCSSProperties({width: "10px"}),
}));

const EllipsisDiv = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const RemoveImg = styled.img`
  cursor: pointer;
`;