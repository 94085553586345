import { configureStore, combineReducers } from '@reduxjs/toolkit';
import { siteReducer} from './reducers/siteReducer';
import thunk from 'redux-thunk';
import onlineDoorReducer from './reducers/doorReducer';
import { eventReducer } from './reducers/eventReducer';
import { doorStatusReducer } from './reducers/doorStatusReducer';
import { userReducer } from './reducers/userReducer';
import { cardStatusReducer } from './reducers/cardStatusReducer';
import { groupNameReducer } from './reducers/groupNameReducer';
import { departmentReducer } from './reducers/departmentReducer';
import { cardModelReducer } from './reducers/cardModelReducer';
import buildingReducer from './reducers/buildingReducer';
import roomReducer from './reducers/roomReducer';
import doorGroupReducer from './reducers/doorGroupReducer';
import { lastUsedDateReducer } from './reducers/lastUsedDateRecuder';
import { cardDialogReducer } from './reducers/cardDialogReducer';
import { masterLevelReducer } from './reducers/masterLevelReducer';

const rootreducer = combineReducers({ 
    siteList: siteReducer, 
    onlineDoorList: onlineDoorReducer, 
    eventList: eventReducer, 
    doorStatus: doorStatusReducer,
    userList: userReducer,
    cardStatusList: cardStatusReducer,
    groupNameList: groupNameReducer,
    departmentList: departmentReducer,
    cardModelList: cardModelReducer,
    buildingList: buildingReducer,
    roomList: roomReducer,
    doorGroupsList: doorGroupReducer,
    lastUsedDateReducer: lastUsedDateReducer,
    cardDialogStorage: cardDialogReducer,
    masterLevelList: masterLevelReducer,
});

const store = configureStore({
    reducer: rootreducer,
    middleware: [thunk]
});

export default store;
export type IRootState = ReturnType<typeof rootreducer>;