import React, { useEffect, useState } from 'react';
import { FilterDiv, Header, Left, StyledPageContainer, StyledPageDisplayContainer, Right, Separator, TableWrapper } from './ManageUsers';
import CustomAddCardSelect from 'components/cards/CustomAddCardSelect';
import { CardTypeUtils } from 'helper/CardTypeUtils';
import { CardStatus, DefaultFilterValues, FilterName, PageNavigation } from 'model/enums';
import DialogGroup from 'components/cards/dialogs/DialogGroup';
import CustomUserFilter, { FILTER_MAX_WIDTH, FILTER_MIN_WIDTH } from 'components/cards/CustomUserFilter';
import { connect, useDispatch, useSelector } from 'react-redux';
import { fetchAllUsers, setNewUsersToPending, setOpenedDialog, setUsersIsRefreshUsersList, setUsersSelectedUserRow, setUsersToDelete } from 'redux/actions/userActions';
import { fetchAllDepartment } from 'redux/actions/departmentActions';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import UserFilter from 'model/UserFilter';
import { filterByTheUserFilters } from 'helper/FilterUtils';
import UserTableRecord from 'model/UserTableRecord';
import UserModel from 'model/UserModel';
import { selectOpenedDialog, selectRefreshUsersList, selectUserList } from 'redux/selectors/userSelector';
import DepartmentDetail from 'model/DepartmentDetail';
import { isDepartmentListLoaded, selectDepartmentList } from 'redux/selectors/departmentSelector';
import DataTableBase from 'components/controls/dataGridTable/DataTableBase';
import { generateColumns, INITIAL_SORT, PAGINATION_SIZE } from 'helper/DataTableUtils';
import { fetchAllCardStatus } from 'redux/actions/cardStatusActions';
import { isCardStatusListLoaded, selectCardStatusList } from 'redux/selectors/cardStatusSelector';
import WindowCard from 'components/cards/WindowCard';
import { requestMasterLevels } from 'redux/actions/masterLevelActions';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';
import SearchBarField from 'components/controls/searchBar/SearchBarField';
import OptionModel from 'model/OptionModel';
import { useConversionUserRecordsToOptionModels } from 'hooks/useConversion';

export function ManageCards(props: any) {

  const [selectedUserTableRecordRow, setSelectedUserTableRecordRow] = useState<UserTableRecord>();
  const [userTableRecordRows, setUserTableRecordRows] = useState<UserTableRecord[]>([]);
  const [optionsOpen, setOptionsOpen] = useState(false);
  const listOfCardTypes = CardTypeUtils.ALL_CARD_TYPES;

  const [selectedDepartment, setSelectedDepartment] = useState<string>(DefaultFilterValues.DepartmentFilter);
  const [selectedCardStatus, setSelectedCardStatus] = useState<string>(DefaultFilterValues.CardStatusFilter);
  const [selectedCardType, setSelectedCardType] = useState<string>(DefaultFilterValues.CardTypeFilter);

  const [departmentFilterIsOpen, setDepartmentFilterIsOpen] = useState(false);
  const [cardStatusFilterIsOpen, setCardStatusFilterIsOpen] = useState(false);
  const [cardTypeFilterIsOpen, setCardTypeFilterIsOpen] = useState(false);

  const [departmentFilter, setDepartmentFilter] = useState<string[]>([DefaultFilterValues.DepartmentFilter]);
  const [cardStatusFilter, setCardStatusFilter] = useState<string[]>([DefaultFilterValues.CardStatusFilter]);
  const [cardTypeFilter, setCardTypeFilter] = useState<string[]>([DefaultFilterValues.CardTypeFilter, ...listOfCardTypes]);

  const [selectedIndexDepartment, setSelectedIndexDepartment] = useState<number|null>(null);
  const [selectedIndexCardStatus, setSelectedIndexCardStatus] = useState<number|null>(0);
  const [selectedIndexCardType, setSelectedIndexCardType] = useState<number|null>(0);
  const [selectedSearchInput, setSelectedSearchInput] = useState<OptionModel>();
  const loadingCardStatusList: boolean = useSelector(isCardStatusListLoaded);
  const cardStatusList: CardStatus[] = useSelector(selectCardStatusList);
  const isRefreshRequested = useSelector(selectRefreshUsersList);
  const listOfMasterLevels = useSelector(selectMasterLevelList);
  const openedDialog = useSelector(selectOpenedDialog);

  const listOfUserOptions = useConversionUserRecordsToOptionModels(userTableRecordRows);
  const listOfUsers: UserModel[] = useSelector(selectUserList);
  const loadingDepartmentList: boolean = useSelector(isDepartmentListLoaded);
  const departmentList: DepartmentDetail[] = useSelector(selectDepartmentList);
  const pageNavigation = PageNavigation.ManageCards;
  const actualSite = ActualSiteLocator();
  const dispatch = useDispatch();

  const applyNewFilterToUsers = () => {
    props.fetchUsers(actualSite);
  }

  const handleSetOfMasterLevels = () => {
    if (listOfMasterLevels && listOfMasterLevels.length === 0) {
      // Loading the Users and storing them in the redux storage.
      requestMasterLevels(actualSite, dispatch);
    }
  }

  useEffect(() => {
    // Loading the Departments and storing them in the redux storage.
    props.fetchDepartments(actualSite);

    // Loading the Card Statuses and storing them in the redux storage.
    props.fetchCardStatus();

    // Loading the Users and storing them in the redux storage.
    props.fetchUsers(actualSite);

    dispatch(setUsersSelectedUserRow());
    handleSetOfMasterLevels();
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  []);

  useEffect(() => {
    if (isRefreshRequested && !openedDialog) {
      handleRefreshUserList();
      dispatch(setUsersIsRefreshUsersList(false));
    }
  }, [isRefreshRequested, openedDialog]);

  useEffect(() => {
    dispatch(setUsersSelectedUserRow(selectedUserTableRecordRow));
  }, [selectedUserTableRecordRow])

  useEffect(() => {
    setSelectedIndexCardType(cardTypeFilter.findIndex(cardType => cardType === selectedCardType));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardTypeFilter]);

  useEffect(() => {
    setCardTypeFilter([
      DefaultFilterValues.CardTypeFilter,
      ...listOfCardTypes.filter(cardType => userTableRecordRows.some(element => cardType === element.cardType))
    ]);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userTableRecordRows])

  useEffect(() => {
    const userFilter: UserFilter = {
      searchModel: selectedSearchInput,
      department: selectedDepartment,
      cardStatus: selectedCardStatus,
      cardType: selectedCardType,
      groupName: DefaultFilterValues.TimeGroupFilter,
    };
    const userTableRecordList: UserTableRecord[] = filterByTheUserFilters(userFilter, listOfUsers, pageNavigation);
    setUserTableRecordRows(userTableRecordList);
    dispatch(setUsersToDelete(userTableRecordList.map(user => ({ userId: user.globalId, isCheckboxChecked: false }))));
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [listOfUsers]);

  useEffect(() => {
    if (!loadingCardStatusList && cardStatusList.length > 0) {
      setCardStatusFilter([
        DefaultFilterValues.CardStatusFilter,
        ...cardStatusList.filter(cardStatus => userTableRecordRows.some(element => cardStatus === element.cardInUse))
      ]);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [loadingCardStatusList, userTableRecordRows]);

  useEffect(() => {
    setSelectedIndexCardStatus(cardStatusFilter.findIndex(cardStatus => cardStatus === selectedCardStatus));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardStatusFilter]);

  useEffect(() => {
    if (!loadingDepartmentList && departmentList.length > 0) {
      setDepartmentFilter((_) => [
        DefaultFilterValues.DepartmentFilter,
        ...departmentList.map((item) => item.Name).filter(department => userTableRecordRows.some(element => element.department === department))
      ]);
    }
  },
  // eslint-disable-next-line react-hooks/exhaustive-deps
  [loadingDepartmentList, userTableRecordRows]);

  useEffect(() => {
    setSelectedIndexDepartment(departmentFilter.findIndex(department => department === selectedDepartment));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [departmentFilter]);

  const departmentChangeHandle = (event: any) => {
    const value = event.target.value;
    setSelectedDepartment(value);
    applyNewFilterToUsers();
  };

  const cardStatusHandleChange = (event: any) => {
    const value = event.target.value;
    setSelectedCardStatus(value);
    applyNewFilterToUsers();
  };

  const cardTypeHandleChange = (event: any) => {
    const value = event.target.value;
    setSelectedCardType(value);
    applyNewFilterToUsers();
  }

  const handleRefreshUserList = () => {
    setSelectedUserTableRecordRow(undefined);
    dispatch(setUsersIsRefreshUsersList(false));
    dispatch(setOpenedDialog(undefined));
    dispatch(setNewUsersToPending([]));
    applyNewFilterToUsers();
  }

  return(
  <React.Fragment>
    <StyledPageContainer>
      <Header>
        <CustomAddCardSelect
            selectOptions={CardTypeUtils.CARD_TYPE_OPTIONS}
            setSelectedOption={(cardType) => CardTypeUtils.convertCardTypeToDialogToOpenType(cardType, (dialogToOpen) => {dispatch(setOpenedDialog(dialogToOpen))})}
            isIconDisplayed
        />
        <SearchBarField
          setValue={(optionModel) => {
            setSelectedSearchInput(optionModel)
            applyNewFilterToUsers();
          }}
          selectOptions={listOfUserOptions}
          inputValue={selectedSearchInput}
          setInputChange={setSelectedSearchInput}
          handleOnEnter={applyNewFilterToUsers}
        />
        <FilterDiv>
          <CustomUserFilter
              filterValue={selectedDepartment}
              filterOnChangeHandle={departmentChangeHandle}
              filterOnOpenHandle={() => setDepartmentFilterIsOpen(true)}
              filterOnCloseHandle={() => setDepartmentFilterIsOpen(false)}
              filterName={FilterName.DepartmentFilter}
              filterOptions={departmentFilter}
              isFilterOpen={departmentFilterIsOpen}
              setSelectedFilterOptionIndex={setSelectedIndexDepartment}
              selectedFilterOptionIndex={selectedIndexDepartment}
              minWidth={FILTER_MIN_WIDTH}
              maxWidth={FILTER_MAX_WIDTH}
          />
          <CustomUserFilter
            filterValue={selectedCardStatus}
            filterOnChangeHandle={cardStatusHandleChange}
            filterOnOpenHandle={() => setCardStatusFilterIsOpen(true)}
            filterOnCloseHandle={() => setCardStatusFilterIsOpen(false)}
            filterName={FilterName.CardStatusFilter}
            filterOptions={cardStatusFilter}
            isFilterOpen={cardStatusFilterIsOpen}
            setSelectedFilterOptionIndex={setSelectedIndexCardStatus}
            selectedFilterOptionIndex={selectedIndexCardStatus}
            minWidth={FILTER_MIN_WIDTH}
            maxWidth={FILTER_MAX_WIDTH}
          />
          <CustomUserFilter
            filterValue={selectedCardType}
            filterOnChangeHandle={cardTypeHandleChange}
            filterOnOpenHandle={() => setCardTypeFilterIsOpen(true)}
            filterOnCloseHandle={() => setCardTypeFilterIsOpen(false)}
            filterName={FilterName.CardType}
            filterOptions={cardTypeFilter}
            isFilterOpen={cardTypeFilterIsOpen}
            setSelectedFilterOptionIndex={setSelectedIndexCardType}
            selectedFilterOptionIndex={selectedIndexCardType}
            minWidth={FILTER_MIN_WIDTH}
            maxWidth={240}
          />
        </FilterDiv>
      </Header>
      <StyledPageDisplayContainer>
        <Left>
          <TableWrapper>
            <DataTableBase
                userTableRecordRows={userTableRecordRows}
                columns={generateColumns(
                  optionsOpen,
                  setOptionsOpen,
                  pageNavigation,
                )}
                initialSort={INITIAL_SORT}
                paginationSize={PAGINATION_SIZE}
                setSelectedUserTableRecordRow={setSelectedUserTableRecordRow}
                isRowSelected={!!selectedUserTableRecordRow}
                isFooterDisplayed
                pageNavigation={pageNavigation}
              />
          </TableWrapper>
        </Left>
        <Right>
          <Separator/>
          <WindowCard
            pageNavigation={pageNavigation}
            selectedUserTableRecordRow={selectedUserTableRecordRow}
            setSelectedUserTableRecordRow={setSelectedUserTableRecordRow}
            setDialogToOpen={(dialogToOpen) => dispatch(setOpenedDialog(dialogToOpen))}
          />
        </Right>
      </StyledPageDisplayContainer>
    </StyledPageContainer>
    <React.Fragment>
      <DialogGroup
          selectedUserRow={selectedUserTableRecordRow}
      />
    </React.Fragment>
  </React.Fragment>
 )
};

const MapStateToProps = (state) => {
  return { userDetails: state.userList.userDetails };
};

const MapDispatchToProps = (dispatch) => {
  return {
    fetchUsers: (actualSite: string) => dispatch(fetchAllUsers(actualSite)),
    fetchDepartments: (actualSite: string) => dispatch(fetchAllDepartment(actualSite)),
    fetchCardStatus: () => dispatch(fetchAllCardStatus()),
  };
};

export default connect(MapStateToProps, MapDispatchToProps)(ManageCards);
