import CardDialogForm, { CardDetailsForm } from 'model/CardDialogForm';
import CardDialogFormError, { CardDetailsFormError } from 'model/CardDialogFormError';

export class CardFormError {
    public static readonly INIT_CARD_DETAILS_FORM_ERROR: CardDetailsFormError = {}
    
    public static readonly INIT_CARD_DIALOG_FORM_ERROR: CardDialogFormError = { cardDetails: this.INIT_CARD_DETAILS_FORM_ERROR};
}

export class CardForm {
    public static readonly INIT_CARD_DETAILS_FORM: CardDetailsForm = { lockReleaseTime: '5' };

    public static readonly INIT_EMPTY_CARD_DIALOG_FORM = new CardDialogForm(this.INIT_CARD_DETAILS_FORM, [], []);
}

/**
 * TODO: In a later task [NSP-253], implement here the validators to the different form elements in both
 * the Card Details, Levels, and Doors menu pages.
 */