import { useState, useEffect } from 'react';
import CardDialogForm, {
  CardDetailsForm,
  CardLevelsForm,
} from 'model/CardDialogForm';
import { useSelector } from 'react-redux';
import { selectSelectedUserRow, selectUserList } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';
import { CardForm } from 'components/cards/dialogs/cardDialog/cardDialogLogic/CardDialogConsts';

export function useCardFormBuilder() {
  const userList = useSelector(selectUserList);
  const listOfMasterLevels = useSelector(selectMasterLevelList);
  const selectedUserRow = useSelector(selectSelectedUserRow);

  const [cardDialogForm, setCardDialogForm] = useState<CardDialogForm>(
    CardForm.INIT_EMPTY_CARD_DIALOG_FORM,
  );

  const initCardLevels = (user?: UserModel): CardLevelsForm[] => {
    const cardLevelForms: CardLevelsForm[] = listOfMasterLevels.map(
      (masterLevel) => ({
        masterLevel,
        isChecked: false,
      }),
    );
    return user
      ? /**
         * TODO: In a later task, when we open the Card Dialog while editing a specific card,
         * modify these values, so the selected card's information can be stored in these variables.
         */
        cardLevelForms
      : cardLevelForms;
  };

  const initCardDetails = (user?: UserModel): CardDetailsForm => {
    return user
      ? {
          /**
           * TODO: In a later task, when we open the Card Dialog while editing a specific card,
           * modify these values, so the selected card's information can be stored in these variables.
           */
          user: undefined,
          startDate: undefined,
          startTime: undefined,
          lastDate: undefined,
          lastTime: undefined,
          department: undefined,
          lockReleaseTime: undefined,
          isUnlimitedDateChecked: undefined,
          building: undefined,
          floor: undefined,
        }
      : CardForm.INIT_CARD_DETAILS_FORM;
  };

  useEffect(() => {
    const user = userList.find((user) => selectedUserRow?.globalId === user.Id);
    setCardDialogForm({
      cardDetails: initCardDetails(user),
      cardLevels: initCardLevels(user),
      cardDoors: [],
    });
  }, [userList, listOfMasterLevels, selectedUserRow]);

  return cardDialogForm;
}
