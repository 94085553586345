import { security } from 'API/auth/Security';
import axios, { AxiosError, InternalAxiosRequestConfig } from 'axios';

export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const REMOTE_SERVER_URL = process.env.REACT_APP_REMOTE_URL;

const axiosInstance = axios.create({ baseURL: BASE_URL });

axiosInstance.interceptors.request.use(
  async (config: InternalAxiosRequestConfig) => {
    const token = await security.fetchAccessToken();
    config.baseURL = BASE_URL;

    if (token) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error: AxiosError) => {
    Promise.reject(error);
  },
);

export default axiosInstance;
