import * as React from 'react';
import UserTableRecord from 'model/UserTableRecord';
import { Box, List, ListItemButton } from '@mui/material';
import { grey } from 'constants/theme';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import { StyledCollapse, StyledGrid, StyledListGrid, StyledListItemTextHeader } from './UserWindowCommonStyle';
import { InformationElements, InformationWindowTitleName, PageNavigation } from 'model/enums';
import { setOverflowContainerWithText } from 'helper/Util';
import CardModel from 'model/CardModel';

type Props = {
  pageNavigation: PageNavigation;
  cardModelToDisplay: CardModel | null;
  selectedUserTableRecordRow: UserTableRecord;
};

const InformationCard = ({selectedUserTableRecordRow, cardModelToDisplay, pageNavigation}: Props) => {
  
    const [pageTitle, setPageTitle] = React.useState('');
    const [openInformation, setOpenInformation] = React.useState(pageNavigation === PageNavigation.ManageUsers);
    const informationElementComponents = Object.values(InformationElements).filter(element => (
            (pageNavigation === PageNavigation.ManageUsers && !([InformationElements.MasterNumber, InformationElements.Issue].some(infoElement => infoElement === element)))
            || (pageNavigation === PageNavigation.ManageCards && element !== InformationElements.Group)
        )
    );

    const windowElementHandler = Object.freeze({
        [InformationElements.UserId]: () => setOverflowContainerWithText(InformationElements.UserId, selectedUserTableRecordRow.userId.toString()),
        [InformationElements.Name]: () => setOverflowContainerWithText(InformationElements.Name, selectedUserTableRecordRow?.name),
        [InformationElements.Department]: () => setOverflowContainerWithText(InformationElements.Department, selectedUserTableRecordRow?.department),
        [InformationElements.Group]: () => setOverflowContainerWithText(InformationElements.Group, selectedUserTableRecordRow?.group),
        [InformationElements.MasterNumber]: () => setOverflowContainerWithText(InformationElements.Group, cardModelToDisplay?.MasterNo),
        [InformationElements.Issue]: () => setOverflowContainerWithText(InformationElements.Group, cardModelToDisplay?.IssueNo),
    });

    const callWindowElements = (optionElement: string) => {
        return windowElementHandler[optionElement].call();
    }

    const handleClickInformationMenu = () => {
        setOpenInformation(!openInformation);
    };
    
    React.useEffect(() => {
        const handler = Object.freeze({
            [PageNavigation.ManageUsers] : () => setPageTitle(InformationWindowTitleName.Information),
            [PageNavigation.ManageCards] : () => setPageTitle(InformationWindowTitleName.Employee),           
        });
        handler[pageNavigation].call();
    }, [pageNavigation]);

  return (
    <List component="nav">
        <ListItemButton onClick={handleClickInformationMenu}>
            <StyledListItemTextHeader >
                {pageTitle}
            </StyledListItemTextHeader>
            {openInformation ? <ExpandLess /> : <ExpandMore />}
        </ListItemButton>
        <StyledCollapse in={openInformation} timeout="auto" unmountOnExit>
            <List component="div" >
                <Box sx={{ width: '100%' }}>
                    <StyledListGrid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 2, md: 3}}>
                        { informationElementComponents.map((optionElement, index) => (
                            <React.Fragment key={index}>
                                <StyledGrid item xs={6}>
                                    {optionElement}
                                </StyledGrid>
                                <StyledGrid item xs={6} color={grey}>
                                    {callWindowElements(optionElement)}
                                </StyledGrid>
                            </React.Fragment>
                        ))}
                    </StyledListGrid>
                </Box>
            </List>
        </StyledCollapse>
    </List>
  );
};

export default InformationCard;