import * as React from 'react';
import CardModel from 'model/CardModel';
import {ReactComponent as ClosedBlackDoor} from 'media/closedBlackDoor.svg';
import styled from 'styled-components';
import { setOverflowContainerWithText } from 'helper/Util';
import { DoorListElements } from 'model/enums';
import RoomModel from 'model/RoomModel';
import { giveBackMasterLevelLastElement } from 'helper/FormatHelper';
import { grey } from 'constants/theme';

type Props = {
  cardModelToDisplay: CardModel | null;
};

const DoorListCardDoorsCard = ({cardModelToDisplay}: Props) => {
  
    const callWindowElements = (optionElement: DoorListElements, room: RoomModel) => {
        const windowElementHandler = Object.freeze({
            [DoorListElements.Building]: () => setOverflowContainerWithText(DoorListElements.Building, room.BuildingNo.toString()),
            [DoorListElements.Floor]: () => setOverflowContainerWithText(DoorListElements.Floor, room.FloorNo.toString()),
            [DoorListElements.LockNumber]: () => setOverflowContainerWithText(DoorListElements.LockNumber, giveBackMasterLevelLastElement(room.MasterLevels)),
            [DoorListElements.LockType]: () => setOverflowContainerWithText(DoorListElements.LockType, room.RoomType),
        });
        return windowElementHandler[optionElement.toString()].call();
    }

    return (
        <StyledElementHolder gap={"32px"}>
            { cardModelToDisplay?.Rooms.map((room, index) => (
                <StyledElementHolder display={"flex"} key={index}>
                    <div>
                        <ClosedBlackDoor />
                    </div>
                    <StyledElementHolder>
                        <StyledRoomName>
                            {room.Name}
                        </StyledRoomName>
                        <StyledElementHolder>
                            { Object.values(DoorListElements).map((doorElement, elementIndex) => (
                                <StyledDoorElementHolder key={elementIndex}>
                                    <StyledDoorElementText>
                                        {doorElement}
                                    </StyledDoorElementText>
                                    <StyledDoorValueText>
                                        {callWindowElements(doorElement, room)}
                                    </StyledDoorValueText>
                                </StyledDoorElementHolder>
                            ))}
                        </StyledElementHolder>
                    </StyledElementHolder>
                </StyledElementHolder>
            ))}
        </StyledElementHolder>
    );
};

export default DoorListCardDoorsCard;

const StyledDoorElementText = styled.div`width: 50%;`;

const StyledDoorValueText = styled.div`color: ${grey};`;

const StyledDoorElementHolder = styled.div`
    display: flex;
    font-size: 14px;
`;

const StyledRoomName = styled.div`
    font-size: 14px;
    font-weight: bold;
`;

export const StyledElementHolder = styled.div<{ display?: string, gap?: string }>((props) => ({ 
    display: props.display ?? "grid",
    gap: props.gap ?? "8px",
    width: "100%",
}));