import React  from 'react';
import UserCardDialog from './userCardDialog/UserCardDialog';
import ReportLostCardDialog from './ReportLostCardDialog';
import ReissueCardDialog from './ReissueCardDialog';
import DeleteDialog from './DeleteDialog';
import { BluetoothUserStatuses, CardStatus, DialogToOpen } from 'model/enums';
import UserTableRecord from 'model/UserTableRecord';
import { notifyError, notifyInfo } from 'helper/NotificationService';
import { useSelector } from 'react-redux';
import { selectOpenedDialog, selectUser } from 'redux/selectors/userSelector';
import UserModel from 'model/UserModel';
import CardDialog from './cardDialog/CardDialog';
import { isCardDialogOpened, isUserDialogOpened } from 'helper/DialogUtils';
import { useDispatch } from 'react-redux';
import { setOpenedDialog, setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import { setCardDialogIsOpen } from 'redux/actions/cardDialogActions';

type Props = {
  selectedUserRow: UserTableRecord | undefined;
};

const DialogGroup = ({selectedUserRow}: Props) => {

  const [isDeleteDialogOpen, setDeleteDialogOpen] = React.useState<boolean>(false);
  const selectedUserModel = useSelector(selectUser(selectedUserRow?.globalId ?? -1));
  const dialogToOpen = useSelector(selectOpenedDialog);
  const dispatch = useDispatch();

  const handleRefreshUserPage = () => {
    handleOpenedDialogToClose();
    dispatch(setUsersIsRefreshUsersList(true));
  }

  const handleOpenedDialogToClose = () => {
    dispatch(setOpenedDialog(undefined));
  }

  React.useEffect(() => {
    dispatch(setCardDialogIsOpen(isCardDialogOpened(dialogToOpen)));

    if (dialogToOpen) {
      switch(dialogToOpen) {
        case DialogToOpen.DeleteUserDialog:
          handleOpenedDialogToClose();
          if (isUserDeleteDialogShow(selectedUserModel)) {
            setDeleteDialogOpen(true);
          }
          break;

        /**
         * TODO: In a later task, implement the Edit Card dialog. After the implementation,
         * please remove this line of code. This line is only a placeholder to notify the users, that they clicked a button.
         */
        case DialogToOpen.ReadCardDialog:
          handleOpenedDialogToClose();
          notifyInfo("[Read Card] clicked!", "");
          break;

        /**
         * TODO: In a later task, implement the Edit Card dialog. After the implementation,
         * please remove this line of code. This line is only a placeholder to notify the users, that they clicked a button.
         */
        case DialogToOpen.EditCardDialog:
          handleOpenedDialogToClose();
          notifyInfo("[Edit Card] clicked!", "");
          break;

        /**
         * TODO: In a later task, implement the Erase Card dialog. After the implementation,
         * please remove this line of code. This line is only a placeholder to notify the users, that they clicked a button.
         */
        case DialogToOpen.EraseCardDialog:
          handleOpenedDialogToClose();
          notifyInfo("[Erase Card] clicked!", "");
          break;
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dialogToOpen]);

  return (<React.Fragment>
      <React.Fragment>
        <UserCardDialog
          openAddUserDialog={isUserDialogOpened(dialogToOpen)}
          setOpenAddUserDialog={handleOpenedDialogToClose}
          selectedUserTableRecordRow={selectedUserRow}
          dialogToOpen={dialogToOpen}
          setRefreshUserList={handleRefreshUserPage}
        />
        <CardDialog />
        <ReportLostCardDialog 
          selectedUserTableRecordRow={selectedUserRow}
          open={dialogToOpen === DialogToOpen.ReportLostDialog} 
          handleSubmit={handleRefreshUserPage}
          handleClose={handleOpenedDialogToClose} 
        />
        <DeleteDialog 
          open={isDeleteDialogOpen} 
          userId={selectedUserRow?.globalId} 
          handleSubmit={() => {
            handleRefreshUserPage();
            setDeleteDialogOpen(false);
          }} 
          handleClose={() => setDeleteDialogOpen(false)} 
        />
        <ReissueCardDialog 
          open={dialogToOpen === DialogToOpen.ReissueCardDialog} 
          selectedUserTableRecordRow={selectedUserRow} 
          handleClose={handleOpenedDialogToClose}
          handleSubmit={handleRefreshUserPage}
        />
      </React.Fragment>
  </React.Fragment>)
}

export default DialogGroup;

export const isCardInUse = (userModel?: UserModel) => userModel?.Card?.CardStatus === CardStatus.InUse;

export const isBluetoothCredentialExist = (userModel?: UserModel) => userModel?.BleMobileNumber && userModel?.UserAccess?.BleUserState !== BluetoothUserStatuses.Undefined;

export const isUserDeleteDialogShow = (userModel?: UserModel): boolean => {
  if (isCardInUse(userModel)) {
    notifyError("You can not delete a user with an In Use card status!", "");
    return false;
  }

  if (isBluetoothCredentialExist(userModel)) {
    notifyError("You can not delete a user with a Suspended or Deleted Bluetooth credential!", "");
    return false;
  }
  return true;
}