import * as React from 'react';
import styled from 'styled-components';
import { StyledContentRowHolder } from '../../userCardDialog/UserDialogCommonStyle';
import { StyledButtonHolder } from '../../userCardDialog/UserCardDialog';
import StyledButton from 'components/controls/button/StyledButton';
import chevronRightArrow from 'media/chevron-right-arrow.svg';
import { OPACITY_OF_ROWS, veryLightGrey, white } from 'constants/theme';
import { TableContainer } from '../../userCardDialog/contentRightSide/UserAccessPage';
import CustomTable from 'components/cards/CustomTable';
import { doorsColData } from 'constants/customColData';
import { alpha } from '@mui/material';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { selectCardDialogForm } from 'redux/selectors/cardDialogSelector';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import RoomModel from 'model/RoomModel';
import BaseUserAccessDialog from '../../userCardDialog/contentRightSide/accessPageDialogs/BaseUserAccessDialog';
import AddRoomsDialog from '../../userCardDialog/contentRightSide/accessPageDialogs/AddRoomsDialog';
import { DefaultFilterValues } from 'model/enums';

const CardDoorsDialog = () => {
  const form = useSelector(selectCardDialogForm);
  const isClearAllDisabled = !!form && form.cardDoors.length === 0;
  const [isAddRoomsDialogOpen, setAddRoomsDialogOpen] = React.useState(false);
  const [selectedLockType, setSelectedLockType] = React.useState<string>(DefaultFilterValues.LockTypeFilter);
  const dispatch = useDispatch();

  const handleClickOnClearAll = () => {
    if (form) {
      dispatch(setCardDialogForm({ ...form, cardDoors: [] }));
    }
  };

  const handleClickOnRoomRemove = (row: RoomModel) => {
    if (form) {
      dispatch(
        setCardDialogForm({
          ...form,
          cardDoors: form?.cardDoors.filter((room) => room.Id !== row.Id),
        }),
      );
    }
  };

  return (
    <StyledMenuPageHolder>
      <CustomContentRowHolder>
        <StyledButtonHolder>
          <StyledButton
            buttonHeight={37}
            buttonWidth={144}
            displayedName={'Add Room'}
            handleOnClick={() => setAddRoomsDialogOpen(true)}
            backgroundColor={white}
            icon={chevronRightArrow}
          />
          <StyledButton
            buttonHeight={37}
            buttonWidth={104}
            displayedName={'Clear All'}
            handleOnClick={handleClickOnClearAll}
            backgroundColor={white}
            disabled={isClearAllDisabled}
          />
        </StyledButtonHolder>
      </CustomContentRowHolder>
      <StyledContentRowHolder>
        <TableContainer>
          <CustomTable
            tableHeight={"auto"}
            customColDataList={doorsColData}
            customColRecords={form?.cardDoors}
            handleClickOnRowRemove={handleClickOnRoomRemove}
            resourceName={'room'}
            evenRowColor={white}
            oddRowColor={alpha(veryLightGrey, OPACITY_OF_ROWS)}
          />
        </TableContainer>
      </StyledContentRowHolder>
      <BaseUserAccessDialog
        isDialogOpen={isAddRoomsDialogOpen}
        setDialogOpen={setAddRoomsDialogOpen}
        isNoActionDialog={false}
        dialogResourceName={"Rooms"}
        MainDialogComponent={
          <AddRoomsDialog
            setSelectedRooms={selectedRooms => form ? dispatch(setCardDialogForm({...form, cardDoors: selectedRooms})) : null}
            selectedRooms={form?.cardDoors ?? []}
            selectedLockType={selectedLockType}
          />
        }
        handleConfirm={() => setAddRoomsDialogOpen(false)}
        selectedLockType={setSelectedLockType}
        selectedResourceNumber={form?.cardDoors.length}
        isHeaderDisplayed
      />
    </StyledMenuPageHolder>
  );
};

export default CardDoorsDialog;

const StyledMenuPageHolder = styled.div`
  width: 684px;
  display: grid;
  gap: 24px;
`;

const CustomContentRowHolder = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
`;
