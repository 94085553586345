import * as React from 'react';
import { StyledDivider } from '../../ReissueCardDialog';
import styled from 'styled-components';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import CardDialogForm, { CardLevelsForm } from 'model/CardDialogForm';
import MasterLevel from 'model/MasterLevel';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { selectCardDialogForm } from 'redux/selectors/cardDialogSelector';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';

const CardLevelsDialog = () => {
  const checkboxWidth = '300px';
  const checkboxHeight = '40px';
  const cardDialogForm = useSelector(selectCardDialogForm);
  const listOfMasterLevels = useSelector(selectMasterLevelList);
  const cardLevelForms = cardDialogForm?.cardLevels;
  const atLeastOneLevelChecked = cardLevelForms ? cardLevelForms.some((masterLevel) => Boolean(masterLevel.isChecked)) : false;
  const allMasterLevelChecked = cardLevelForms ? cardLevelForms.every((masterLevel) =>Boolean(masterLevel.isChecked)) : false;
  const dispatch = useDispatch();

  const updateForm = (cardLevels: CardLevelsForm[]) => {
    if (cardDialogForm) {
      const form: CardDialogForm = { ...cardDialogForm, cardLevels };
      dispatch(setCardDialogForm(form));
    }
  }

  const setSelectedMasterLevels = (
    isChecked: boolean,
    masterLevel: MasterLevel,
    cardLevelForms: CardLevelsForm[],
  ): CardLevelsForm[] =>
    cardLevelForms.map((cardLevelForm) => ({
      masterLevel: cardLevelForm.masterLevel,
      isChecked:
        cardLevelForm.masterLevel?.ID === masterLevel.ID
          ? isChecked
          : cardLevelForm.isChecked,
    }))
  ;

  return (
    <React.Fragment>
      { cardLevelForms ?
        <StyledMenuPage>
          <CheckboxField
            boxWidth={checkboxWidth}
            boxHeight={checkboxHeight}
            displayedName="Access All Master Levels"
            isIndeterminate={!allMasterLevelChecked && atLeastOneLevelChecked}
            setValue={(isChecked) =>
              updateForm(
                cardLevelForms.map((cardLevelForm) => ({
                  masterLevel: cardLevelForm.masterLevel,
                  isChecked,
                })),
              )
            }
            inputValue={allMasterLevelChecked}
          />
          <StyledDivider />
          {listOfMasterLevels.map(masterLevel => (
            <div key={masterLevel.ID}>
              <CheckboxField
                boxWidth={checkboxWidth}
                boxHeight={checkboxHeight}
                displayedName={masterLevel.Name}
                setValue={(isChecked: boolean) => updateForm(setSelectedMasterLevels(isChecked, masterLevel, cardLevelForms))}
                inputValue={
                  cardLevelForms.find(
                    (cardLevelForm) =>
                      cardLevelForm.masterLevel?.ID === masterLevel.ID,
                  )?.isChecked
                }
              />
            </div>
          ))}
        </StyledMenuPage>
      : null }
    </React.Fragment>
  );
};

export default CardLevelsDialog;

const StyledMenuPage = styled.div`
  width: 650px;
`;
