import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import DialogTexts from 'model/DialogTexts';
import { CSSProperties } from 'react';

type Props = {
    handleSubmit: () => void;
    handleClose: () => void;
    dialogText: DialogTexts;
    open: boolean;
    style?: {
      dialog?: CSSProperties;
      title?: CSSProperties;
      content?: CSSProperties;
      contentText?: any;
      actions?: CSSProperties;
      submitButton?: CSSProperties;
      cancelButton?: CSSProperties;
  };
}

const TemplateDialog = ({
    handleSubmit,
    handleClose,
    dialogText,
    open,
    style,
}: Props) => {
    return (
          <Dialog
            open={open}
            onClose={handleClose}
            PaperProps={{
              component: 'form',
              style: style?.dialog,
              onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
                event.preventDefault();
                handleSubmit();
              },
            }}
            disableScrollLock={true}
          >
            <DialogTitle style={style?.title}>{dialogText.title}</DialogTitle>
            <DialogContent style={style?.content}>
              <DialogContentText style={style?.contentText}>
                <React.Fragment>
                  {dialogText.contentText}
                </React.Fragment>
              </DialogContentText>
            </DialogContent>
            <DialogActions style={style?.actions}>
              <Button onClick={handleClose} style={style?.cancelButton}>{dialogText.cancelButton}</Button>
              <Button type="submit" style={style?.submitButton}>{dialogText.submitButton}</Button>
            </DialogActions>
          </Dialog>
      );
}

export default TemplateDialog;