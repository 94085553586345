import UserFilter from "model/UserFilter";
import UserTableRecord from "model/UserTableRecord";
import UserModel from "model/UserModel";
import { DefaultFilterValues, PageNavigation } from "model/enums";
import OptionModel from "model/OptionModel";

export const filterByTheUserFilters = (
    userFilter: UserFilter, 
    userList: UserModel[], 
    pageNavigation: PageNavigation,
): UserTableRecord[] => {
    filterSiteSpecificAttributes(userFilter, userList, pageNavigation, (filteredUserList: UserModel[]) => userList = filteredUserList);
    userList = filterTheCommonAttributes(userFilter, userList);
    return userList.map(item => ({
      userId: Number(item.UserNumber),
      name: item.Name,
      department: item.Department.DepartmentName,
      group: item.Group.GroupName,
      globalId: item.Id,
      cardId: item.CardID,
      cardInUse: item.Card?.CardStatus ?? undefined,
      cardType: item.Card?.CardType,
      limitedDate: item.Card?.Expiry,
    }));
}

const filterSiteSpecificAttributes = (
    userFilter: UserFilter, 
    userList: UserModel[], 
    pageNavigation: PageNavigation,
    callback: (UserModel: UserModel[]) => void
) => {
    const handler = Object.freeze({
        [PageNavigation.ManageUsers]: () => {
            if (userFilter.groupName !== DefaultFilterValues.TimeGroupFilter) {
                callback(userList.filter(user => user.Group.GroupName.includes(userFilter.groupName)));
            }
        },
        [PageNavigation.ManageCards]: () => {
            if (userFilter.cardType !== DefaultFilterValues.CardTypeFilter) {
                callback(userList.filter(user => user.Card?.CardType.includes(userFilter.cardType)));
            }
        }, 
    });
    handler[pageNavigation].call();
}

const filterTheCommonAttributes = (
    userFilter: UserFilter, 
    userList: UserModel[],
): UserModel[] => {
    if (userFilter.department !== DefaultFilterValues.DepartmentFilter) {
        userList = userList.filter(user => user.Department.DepartmentName.includes(userFilter.department));
    }
    if (userFilter.cardStatus !== DefaultFilterValues.CardStatusFilter) {
      userList = userList.filter(user => user.Card?.CardStatus === userFilter.cardStatus);
    }
    if (userFilter.searchModel) {
      userList = filterBySearchBar(userList, userFilter.searchModel);
    }
    return userList
}

const filterBySearchBar = (
    userList: UserModel[], 
    searchModel: OptionModel,
): UserModel[] => {
    const filteredUsers = userList.filter(user =>
    user.Name.toLowerCase().includes(searchModel.label.toLowerCase())
    || user.Department.DepartmentName.toLowerCase().includes(searchModel.label.toLowerCase())
    || user.Id.toString().includes(Number(searchModel.userNumber).toString())
    || (user.Email && user.Email.toLowerCase().includes(searchModel.label.toLowerCase()))
    )
    return filteredUsers;
}