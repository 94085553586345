import React  from 'react';
import DialogTexts from "model/DialogTexts";
import styled from 'styled-components';
import StyledButton from 'components/controls/button/StyledButton';
import { DialogToOpen } from 'model/enums';
import { blue } from './theme';

export const CHANGE_MOBILE_DIALOG_TEXT: DialogTexts = {
    title: "Change Mobile",
    contentText: (
        <React.Fragment>
            <span>
                The user’s mobile will be deactivated.
            </span>
            <br/>
            <span>
                Are you sure you want to perform this action?
            </span>
        </React.Fragment>
    ),
    submitButton: "Deactivate Mobile",
    cancelButton: "Cancel"
};

export const SMS_SENT_OUT_DIALOG_TEXT: DialogTexts = {
    title: "SMS Has Been Sent",
    contentText: (
        <React.Fragment>
            <span>
                SMS has been sucessfully sent to the user.
            </span>
        </React.Fragment>
    ),
    submitButton: "",
    cancelButton: "Close"
};

export const SUSPEND_USER_DIALOG_TEXT: DialogTexts = {
    title: "Suspend User",
    contentText: (
        <React.Fragment>
            <span>
                The user will be suspended and won’t be able to
            </span>
            <br/>
            <span>
                access their assigned doors.
            </span>
            <br/>
            <span>
                Are you sure you want to perform this action?
            </span>
        </React.Fragment>
    ),
    submitButton: "Suspend",
    cancelButton: "Cancel"
};

export const DELETE_USER_DIALOG_TEXT: DialogTexts = {
    title: "Delete User",
    contentText: (
        <React.Fragment>
            <span>
                The user will be deleted from the could server
            </span>
            <br/>
            <span>
                and won’t be able to access their account.
            </span>
            <br/>
            <span>
                Are you sure you want to perform this action?
            </span>
        </React.Fragment>
    ),
    submitButton: "Delete",
    cancelButton: "Cancel"
};

export const ACTIVATE_USER_DIALOG_TEXT: DialogTexts = {
    title: "Activate User",
    contentText: (
        <React.Fragment>
            <span>
                The user will be re-activated.
            </span>
            <br/>
            <span>
                Are you sure you want to perform this action?
            </span>
        </React.Fragment>
    ),
    submitButton: "Re-Activate",
    cancelButton: "Cancel"
};

export const RE_ACTIVATE_USER_DIALOG_TEXT: DialogTexts = {
    title: "Re-Activate User",
    contentText: ACTIVATE_USER_DIALOG_TEXT.contentText,
    submitButton: "Re-Activate",
    cancelButton: "Cancel"
};

export const renderNoDoorToDisplayContent = () => (<StyledText>Card has no door assigned.</StyledText>);

export const renderNoSelectionUserContent = () => (<p>Select a user to see more information!</p>);

export const renderNoSelectionCardContent = (callback: (dialogToOpen: DialogToOpen) => void) => (
    <React.Fragment>
        <p>Select a card to see more information</p>
        <p>or</p>
        <StyledButtonContainer>
            <StyledButton
                handleOnClick={() => callback(DialogToOpen.ReadCardDialog)}
                displayedName={"Read Card"}
                buttonHeight={40}
                buttonWidth={144}
                isFilledButton={true}
                backgroundColor={blue}
            />
        </StyledButtonContainer>
    </React.Fragment>
);

const StyledText = styled.p`
    font-size: 14px !important;
`;

const StyledButtonContainer = styled.div`
    align-items: center;
    display: inline-flex;
`;

export const bulkDeleteUsersContent = (usersToDelete: number): DialogTexts => {
    const renderUserTextByUserCount = `User${usersToDelete > 1 ? `s` : ``}`;
    const renderUserDeletionCount = `${usersToDelete > 0 ? `(${usersToDelete})` : ``}`;
    return ({
        title: `Bulk Delete ${renderUserTextByUserCount}`,
        contentText: (
            <StyledContextSeparator>
                <div>
                    <div>Are you sure you want to delete {usersToDelete > 1 ? "these users" : "this user"}? They will not be able to</div>
                    <div>access any locks with their card.</div>
                </div>
                <div>
                    <div>Be aware that you cannot delete a user if they have</div>
                    <div>bluetooth credentials and if their card has ’In Use’ status.</div>
                </div>
            </StyledContextSeparator>
        ),
        submitButton: `Delete ${renderUserTextByUserCount} ${renderUserDeletionCount}`,
        cancelButton: "Cancel"
    });
};

const StyledContextSeparator = styled.div`
    display: grid;
    gap: 16px;
`;