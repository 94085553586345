import { BleCommand, CommandCategory } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import BluetoothResponse from 'model/BluetoothResponse';
import { notifySuccess } from 'helper/NotificationService';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

export const addUpdateBleUser = async (siteName: string, userId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.BleService,
      BleCommand.AddUpdateUser,
      [`${userId}`],
    );
    if (!data || !data.data) {
      notifyCommandError('The user cannot be added to the NSP Ble cloud system or the server timed out.', null);
      return null;
    }
    notifySuccess("The user is successfully added to the NSP Ble Cloud", "");
    const response: BluetoothResponse = data.data;
    return response;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing BleService AddUpdateUser command', error)
    return null;
  }
};