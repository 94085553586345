import * as React from 'react';
import styled from 'styled-components';
import SingleDatePicker from './SingleDatePicker';
import SingleTimePicker from './SingleTimePicker';
import { Dayjs } from 'dayjs';
import CheckboxField from '../checkboxes/CheckboxField';

type Props = {
  dateAndTimePickerWidth?: string;
  dateAndTimePickerHeight?: string;
  isUnlimitedDateChecked?: boolean;
  titleForm?: {
    name?: string,
    isCheckboxDisplayed?: boolean,
    checkboxValue?: boolean,
  }
  dateForm?: {
    date?: Dayjs | null,
    error?: string,
    disabled?: boolean,
  };
  timeForm?: {
    time?: Dayjs | null,
    error?: string,
    disabled?: boolean,
  };
  isRequired?: boolean;
  startAfterEndError?: string;
  setDate: (date: Dayjs) => void;
  setTime: (time: Dayjs) => void;
  setCheckbox?: (isChecked: boolean) => void;
};

const DateAndTimePickerContainer = ({
  dateAndTimePickerWidth = '160px',
  dateAndTimePickerHeight = '40px',
  isUnlimitedDateChecked,
  titleForm,
  dateForm,
  timeForm,
  startAfterEndError,
  isRequired,
  setDate,
  setTime,
  setCheckbox,
}: Props) => {
  const handleDateTimeErrorMessageDisplay = (
    errorMessageToDisplay: string | undefined,
  ) => {
    return !isUnlimitedDateChecked ? errorMessageToDisplay : undefined;
  };

  return (
    <FooterContainer>
        <DateContainer>
            <StyledDateTitleHolder>
                { titleForm?.isCheckboxDisplayed ? 
                    <CheckboxField
                        boxWidth={"220px"}
                        boxHeight={"40px"}
                        displayedName={titleForm?.name}
                        setValue={(isChecked) => setCheckbox ? setCheckbox(isChecked) : null}
                        inputValue={isUnlimitedDateChecked ? false : titleForm?.checkboxValue}
                        isDisabled={isUnlimitedDateChecked}
                        isRequired={isRequired}
                    />
                : titleForm?.name }
            </StyledDateTitleHolder>
            <StyledDateHolder>
                <SingleDatePicker
                inputFieldWidth={dateAndTimePickerWidth}
                inputFieldHeight={dateAndTimePickerHeight}
                setValue={setDate}
                inputValue={dateForm?.date}
                hasLabel={false}
                isDisabled={isUnlimitedDateChecked || dateForm?.disabled}
                invalidFormMessage={handleDateTimeErrorMessageDisplay(dateForm?.error ?? startAfterEndError)}
                />
                <SingleTimePicker
                inputFieldWidth={dateAndTimePickerWidth}
                inputFieldHeight={dateAndTimePickerHeight}
                setValue={setTime}
                inputValue={timeForm?.time}
                hasLabel={false}
                isDisabled={isUnlimitedDateChecked || timeForm?.disabled}
                invalidFormMessage={handleDateTimeErrorMessageDisplay(timeForm?.error)}
                />
            </StyledDateHolder>
        </DateContainer>
    </FooterContainer>
  );
};

export default DateAndTimePickerContainer;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 12px;
`;

const DateContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledDateHolder = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 16px;
  margin-bottom: 12px;
`;

const StyledDateTitleHolder = styled.div`
    display: flex;
    align-items: center;
`;
