import { AxiosError } from 'axios';
import { CardCommand, CommandCategory } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import CardModel from 'model/CardModel';
import { notifySuccess } from 'helper/NotificationService';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

const RESPONSE_SUCCESS = '0';
const RESPONSE_ACK = '1';

const notifyCardStatusError = (error: AxiosError | null) => {
    notifyCommandError('Error while executing card status command', error);
};

export const getCardById = async (siteName: string, cardId: number): Promise<CardModel | null> => {
    try {
      const data = await postCommand(
        siteName,
        CommandCategory.CardService,
        CardCommand.GetCardById,
        [`${cardId}`],
      );
      if (!data || !data.data) {
        notifyCommandError('No cards found or the server timed out.', null);
        return null;
      }
      const cardModel: CardModel = data.data;
      return cardModel;
    } catch (err) {
      const error = getAxiosErrorOrNull(err);
      notifyCardStatusError(error);
      return null;
    }
}

export const reportLostCard = async (siteName: string, operatorId: number, cardId: number): Promise<string | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.ReportCardLost,
      [operatorId, cardId]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    notifySuccess("Card is successfully reported as lost.", "");
    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const makeReportLostCard = async (siteName: string, operatorId: number, encoderId: number, cardId: number): Promise<string | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.MakeReportLostCard,
      [operatorId, encoderId, cardId]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    notifySuccess("Report Lost Card is successfully created.", "");
    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}

export const reissueCard = async (siteName: string, operatorId: number, encoderId: number, cardId: number, expiryDate: string | null): Promise<string | null> => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.CardService,
      CardCommand.ReIssueCard,
      [operatorId, encoderId, cardId, expiryDate]
    );

    if (data.data.Result !== RESPONSE_SUCCESS && data.data.Result !== RESPONSE_ACK) {
      notifyCommandError(data.data.Description, null);
      return null;
    }

    notifySuccess("Card is successfully reissued.", "");
    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCardStatusError(error);

    return null;
  }
}