import dayjs from "dayjs";
import { DateActions } from "redux/constants/lastUsedDateActions";
import LastUsedDateActionTypes from "redux/constants/lastUsedDateActionTypes";

type DateType = {
    loading: boolean,
    lastUsedDate: dayjs.Dayjs | undefined,
}

const initialState: DateType = {
    loading: true,
    lastUsedDate: undefined,
}

export const lastUsedDateReducer = (
    state: DateType = initialState,
    action: DateActions,
  ) => {
    switch (action.type) {
      case LastUsedDateActionTypes.FETCH_LAST_USED_DATE_REQUEST:
        return {
          ...state,
          loading: true,
        };
      case LastUsedDateActionTypes.FETCH_LAST_USED_DATE_SUCCESS:
        return {
          loading: false,
          lastUsedDate: action.payload,
        };
      default:
        return state;
    }
};