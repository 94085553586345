import { createSelector } from "@reduxjs/toolkit";
import UserModel from "model/UserModel";
import { IRootState } from "redux/store";

export const selectIsUserListStillLoading = (state: IRootState) => state.userList.loading;

export const selectUserList = (state: IRootState) => state.userList.userModels;

export const selectRefreshUsersList = (state: IRootState) => state.userList.isRefreshUserList;

export const selectSelectedUserRow = (state: IRootState) => state.userList.selectedUserRow;

export const selectOpenedDialog = (state: IRootState) => state.userList.openedDialog;

export const selectUsersToDelete = (state: IRootState) => state.userList.usersToDelete;

export const selectPageNavigation = (state: IRootState) => state.userList.pageNavigation;

export const selectNewUsersOnPending = (state: IRootState) => state.userList.newUsersOnPendingModels;

export const selectLastUserToAddedToNewUsersOnPending = (state: IRootState): UserModel | undefined => 
    state.userList.newUsersOnPendingModels[state.userList.newUsersOnPendingModels.length - 1];

export const selectUserListStorage = (state: IRootState) => state.userList;

const selectUserById = createSelector(
    selectUserList,
    (_: IRootState, id: number) => id,
    (users, id: number) => users.find((user) => user.Id === id),
);
  
export const selectUser = (id: number | undefined) => (state: IRootState) =>
    id ? selectUserById(state, id) : undefined;
  