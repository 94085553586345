import { AxiosError } from 'axios';
import { CommandCategory, UserCommand } from 'model/enums';
import postCommand from './Command';
import { notifyCommandError } from './Command';
import UserModel from 'model/UserModel';
import NewUserModel from 'model/NewUserModel';
import { notifySuccess } from 'helper/NotificationService';
import UserImageModel from 'model/UserImageModel';
import { getAxiosErrorOrNull } from 'API/axios/AxiosErrorHelper';

const OBJECT_NOT_FOUND = '2';

const notifyUserDepartmentError = (error: AxiosError| null) => {
    notifyCommandError('Error while executing user department command', error);
};

const notifyUserByIdError = (error: AxiosError| null) => {
  notifyCommandError('Error while executing get user by id command', error);
};

const notifyUserCreationError = (error: AxiosError| null) => {
  notifyCommandError('Error while executing create new user command', error);
};

const notifyUserUpdateError = (error: AxiosError | null) => {
  notifyCommandError('Error while executing update user command', error);
};

const notifyUserDeletionError = (error: AxiosError | null) => {
  notifyCommandError('Error while executing delete user command', error);
};

export const getUsersByDepartment = async (siteName: string, departmentId: number) => {
    try {
      const data = await postCommand(
        siteName,
        CommandCategory.UserService,
        UserCommand.GetUsersByDepartment,
        [`${departmentId}`],
      );
      if (!data || !data.data) {
        notifyCommandError('No users found or the server timed out.', null);
        return [];
      }
      const userModel: UserModel[] = data.data;
      return userModel;
    } catch (err) {
      const error = getAxiosErrorOrNull(err);
      notifyUserDepartmentError(error);
      return [];
    }
  }

export const getUserById = async (siteName: string, userId: number) => {
  try {
    const data = await postCommand(
      siteName,
      CommandCategory.UserService,
      UserCommand.GetUserById,
      [`${userId}`],
    );
    if (!data || !data.data) {
      notifyCommandError('The user is not found or the server timed out.', null);
      return;
    }
    const userModel: UserModel = data.data;
    return userModel;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyUserByIdError(error);
    return;
  }
}

export const createNewUser = async (siteName: string, userModel: NewUserModel | undefined) => {
  try {
    if (!userModel) {
      notifyCommandError('The user did not created because you did not provided a user to create.', null);
      return;
    }
    const data = await postCommand(
      siteName,
      CommandCategory.UserService,
      UserCommand.CreateNewUser,
      [userModel],
    );
    if (!data || !data.data) {
      notifyCommandError('The user did not created or the server timed out.', null);
      return;
    }
    notifySuccess('The user is successfully created', '');
    const newlyCreatedUserModel: UserModel = data.data;
    return newlyCreatedUserModel;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyUserCreationError(error);
    return;
  }
}

export const updateExistingUser = async (siteName: string, userModel: NewUserModel | undefined) => {
  try {
    if (!userModel) {
      notifyCommandError('The user did not get updated because you did not provided a user to update.', null);
      return;
    }
    const data = await postCommand(
      siteName,
      CommandCategory.UserService,
      UserCommand.UpdateUser,
      [userModel],
    );
    if (!data || !data.data || data.data.Result === OBJECT_NOT_FOUND) {
      notifyCommandError('The user did not get updated or the server timed out.', null);
      return;
    }
    notifySuccess('The user is successfully updated', '');
    const newlyCreatedUserModel: UserModel = JSON.parse(data.data.Id);
    return newlyCreatedUserModel;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyUserUpdateError(error);
    return;
  }
}

export const deleteUser = async (siteName: string, userId?: number, isSuccessMessageShown: boolean = true) => {
  try {
    if (!userId) {
      notifyCommandError('You have to provide a valid user ID.', null);
      return;
    }
    
    const data = await postCommand(
      siteName,
      CommandCategory.UserService,
      UserCommand.DeleteUser,
      [userId]
    );
    
    if (!data || !data.data || data.data.Result === OBJECT_NOT_FOUND) {
      notifyCommandError('The user cannot be deleted or not found or the server timed out.', null);
      return;
    }

    if (isSuccessMessageShown) notifySuccess('The user is successfully deleted', '');
    return data.data;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyUserDeletionError(error);

    return [];
  }
}

export const getUserImage = async (siteName: string, userId: number | undefined) => {
  try {
    if (!userId) {
      notifyCommandError('You have to provide a valid user ID.', null);
      return;
    }
    
    const data = await postCommand(
      siteName,
      CommandCategory.UserService,
      UserCommand.GetUserImage,
      [userId]
    );
    
    if (!data || !data.data) {
      notifyCommandError('The user image is not found or the server timed out.', null);
      return;
    }
    if (data.data.Result === OBJECT_NOT_FOUND) {
      return;
    }
    
    const userImage: UserImageModel = data.data;
    return userImage;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing get user image command', error);
    return;
  }
}

export const addUserImage = async (siteName: string, userId: number | undefined, base64Image: string | undefined) => {
  try {
    if (!userId) {
      notifyCommandError('You have to provide a valid user ID.', null);
      return;
    }
    if (!base64Image) {
      notifyCommandError('You have to provide a file to add to the user.', null);
      return;
    }
    
    const data = await postCommand(
      siteName,
      CommandCategory.UserService,
      UserCommand.AddUserImage,
      [userId, base64Image]
    );
    
    if (!data || !data.data || data.data.Result === OBJECT_NOT_FOUND) {
      notifyCommandError('The user image can not be saved or the server timed out.', null);
      return;
    }    
    return;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing add user image command', error);
    return;
  }
}

export const deleteUserImage = async (siteName: string, userId: number | undefined) => {
  try {
    if (!userId) {
      notifyCommandError('You have to provide a valid user ID.', null);
      return;
    }
    
    const data = await postCommand(
      siteName,
      CommandCategory.UserService,
      UserCommand.DeleteUserImage,
      [userId]
    );
    
    if (!data || !data.data || data.data.Result === OBJECT_NOT_FOUND) {
      notifyCommandError('The user image can not be deleted or the server timed out.', null);
      return;
    }    
    return;
  } catch (err) {
    const error = getAxiosErrorOrNull(err);
    notifyCommandError('Error while executing delete user image command', error);
    return;
  }
}