import React, { useState } from 'react';
import TabsField from 'components/controls/tabs/TabsField';

export default function NavigationBarCard(props: any) {
  const tabs = ['Overview', 'Manage Users', 'Manage Cards']
  const [selectedTab, setSelectedTab] = useState(0);

  const handleItemClick = (index) => {
    setSelectedTab(index);
    props.handleLocation(tabs[index]);
  };

  return (
    <>
      <TabsField
        height={50}
        width={400}
        selectedTab={selectedTab}
        tabs={tabs}
        handleOnClick={handleItemClick}
      />
    </>
  );
}