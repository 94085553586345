import * as React from 'react';
import TimeGroup from 'model/TimeGroup';
import DepartmentDetail from 'model/DepartmentDetail';
import { connect, useSelector } from 'react-redux';
import { selectGroupNameList } from 'redux/selectors/groupNameSelector';
import { selectDepartmentList } from 'redux/selectors/departmentSelector';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import { addUserImage, createNewUser, deleteUserImage, updateExistingUser } from 'API/commands/UserCommands';
import UserDialogFormError, { AccessError, AdditionalInformationError, BluetoothError, InformationError } from 'model/UserDialogFormError';
import { AccessValidators, AdditionalInformationValidators, BluetoothValidators, InformationValidators, UserForm, UserFormError } from './UserDialogConsts';
import { CustomInputFieldValidator } from 'helper/ValidatorsUtil';
import { notifyCommandError } from 'API/commands/Command';
import { DatesUtil } from 'helper/DatesUtil';
import NewUserModel from 'model/NewUserModel';
import UserModel from 'model/UserModel';
import { selectNewUsersOnPending, selectUserList } from 'redux/selectors/userSelector';
import { FilesUtil } from 'helper/FilesUtil';
import dayjs from 'dayjs';
import { fetchLastUsedDateRequester } from 'redux/actions/lastUsedDateActions';
import UserDialogForm from 'model/UserDialogForm';
import { PhonesUtil } from 'helper/PhonesUtil';
import { addUpdateBleUser } from 'API/commands/BluetoothCommands';
import { BluetoothUserStatuses, ImageActions } from 'model/enums';
import { ConversionsUtil } from 'helper/ConversionsUtils';
import { isAllFormInputsValid } from 'helper/DialogUtils';
import { useDispatch } from 'react-redux';
import { setNewUsersToPending } from 'redux/actions/userActions';

type Props = {
    fetchAccessEndDate: any,
    userDialogForm?: UserDialogForm;
    isUserEdited?: boolean;
    isSubmitClicked?: boolean;
    isBluetoothPageDisabled?: boolean;
    setUserDialogFormError: (userDialogFormError: UserDialogFormError) => void;
    handleClickOnClose: () => void;
    setSubmitClicked: (isSubmitClicked: boolean) => void;
    setRefreshUserList: () => void;
};

export const ValidateUserForm = ({
    fetchAccessEndDate,
    isSubmitClicked = false,
    userDialogForm,
    isUserEdited = false,
    isBluetoothPageDisabled,
    setUserDialogFormError,
    handleClickOnClose,
    setSubmitClicked,
    setRefreshUserList,
}: Props) => {

    const listOfUsers: UserModel[] = useSelector(selectUserList);
    const listOfGroups: TimeGroup[] = useSelector(selectGroupNameList);
    const listOfDepartments: DepartmentDetail[] = useSelector(selectDepartmentList);
    const listOfNewUsersOnPending = useSelector(selectNewUsersOnPending);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    const closeValidation = () => {
        setSubmitClicked(false);
    }

    const getUserInformationFormErrorModel = (): InformationError => {
        return (userDialogForm ? {
            userNameError: CustomInputFieldValidator.validateInputFieldByValidators(InformationValidators.USER_NAME_VALIDATORS, userDialogForm.information.name),
            departmentNameError: CustomInputFieldValidator.validateInputFieldByValidators(InformationValidators.DEPARTMENT_NAME_VALIDATORS, userDialogForm.information.departmentName),
            groupNameError: CustomInputFieldValidator.validateInputFieldByValidators(InformationValidators.GROUP_NAME_VALIDATORS, userDialogForm.information.groupName),
        } : UserFormError.INIT_USER_DIALOG_INFORMATION_FORM_ERROR);
    }

    const getUserAdditionalInformationFormErrorModel = (): AdditionalInformationError => {
        return (userDialogForm ? {
            birthDateError: CustomInputFieldValidator.validateInputFieldByValidators(AdditionalInformationValidators.BIRTH_DATE_VALIDATORS, userDialogForm.additionalInformation.birthDate?.toDate()),
            addressError: CustomInputFieldValidator.validateInputFieldByValidators(AdditionalInformationValidators.ADDRESS_VALIDATORS, userDialogForm.additionalInformation.address),
            telephoneError: CustomInputFieldValidator.validateInputFieldByValidators(AdditionalInformationValidators.PHONE_VALIDATORS, userDialogForm.additionalInformation.telephone),
            commentError: CustomInputFieldValidator.validateInputFieldByValidators(AdditionalInformationValidators.COMMENT_VALIDATORS, userDialogForm.additionalInformation.comment),
            positionError: CustomInputFieldValidator.validateInputFieldByValidators(AdditionalInformationValidators.POSITION_VALIDATORS, userDialogForm.additionalInformation.position),
            typeError: CustomInputFieldValidator.validateInputFieldByValidators(AdditionalInformationValidators.TYPE_VALIDATORS, userDialogForm.additionalInformation.type),
        } : UserFormError.INIT_USER_DIALOG_ADDITIONAL_INFORMATION_FORM_ERROR);
    }

    const getUserAccessFormErrorModel = (): AccessError => {
        return (userDialogForm && !userDialogForm.accessForm.isUnlimitedDateChecked) ? {
            startDateError: CustomInputFieldValidator.validateInputFieldByValidators(AccessValidators.START_DATE_VALIDATORS, userDialogForm.accessForm.startDate),
            startTimeError: CustomInputFieldValidator.validateInputFieldByValidators(AccessValidators.START_TIME_VALIDATORS, userDialogForm.accessForm.startTime),
            endDateError: CustomInputFieldValidator.validateInputFieldByValidators(AccessValidators.END_DATE_VALIDATORS, userDialogForm.accessForm.endDate),
            endTimeError: CustomInputFieldValidator.validateInputFieldByValidators(AccessValidators.END_TIME_VALIDATORS, userDialogForm.accessForm.endTime),
            startAfterEndError: CustomInputFieldValidator.validateInputFieldByValidators(
                AccessValidators.START_AFTER_END_VALIDATORS,
                DatesUtil.combineDateAndTimeToFormat(userDialogForm.accessForm.startDate, userDialogForm.accessForm.startTime),
                DatesUtil.combineDateAndTimeToFormat(userDialogForm.accessForm.endDate, userDialogForm.accessForm.endTime)
            )
        } : UserFormError.INIT_USER_DIALOG_ACCESS_FORM_ERROR;
    }

    const getUserBluetoothErrorModel = (): BluetoothError => {
        return userDialogForm ? {
            usernameError: CustomInputFieldValidator.validateInputFieldByValidators(BluetoothValidators.USER_NAME_VALIDATORS, userDialogForm.bluetoothForm.username),
            emailError: CustomInputFieldValidator.validateInputFieldByValidators(BluetoothValidators.EMAIL_VALIDATORS, userDialogForm.bluetoothForm.email),
            mobileNumberError: CustomInputFieldValidator.validateInputFieldByValidators(BluetoothValidators.MOBILE_NUMBER_VALIDATORS, PhonesUtil.convertCountryCodeAndNumbersToPhoneNumber(userDialogForm.bluetoothForm.mobileCountryCode, userDialogForm.bluetoothForm.mobileNumber)),
            lockReleaseTimeError: CustomInputFieldValidator.validateInputFieldByValidators(BluetoothValidators.LOCK_RELEASE_VALIDATORS, userDialogForm.bluetoothForm.lockReleaseTime),
        } : UserFormError.INIT_USER_DIALOG_BLUETOOTH_FORM_ERROR;
    }

    const isAllOfTheFormValid = (): boolean => {
        const userDialogFormError: UserDialogFormError = {
            informationError: getUserInformationFormErrorModel(),
            additionalInformationError: getUserAdditionalInformationFormErrorModel(),
            accessError: getUserAccessFormErrorModel(),
            bluetoothError: getUserBluetoothErrorModel(),
        };
        setUserDialogFormError(userDialogFormError);
        return isAllFormInputsValid(userDialogFormError);
    }

    const handleClickOnSubmit = () => {
        if (isAllOfTheFormValid() && userDialogForm?.information.name) {
            const user = listOfUsers.find(item => item.UserNumber === userDialogForm.information.userId);
            const selectedGroup = listOfGroups.find(item => item.GroupName === userDialogForm.information.groupName);
            const selectedDepartment = listOfDepartments.find(item => item.Name === userDialogForm.information.departmentName);
            if (!selectedGroup) {
                notifyCommandError("The system did not find the user selected group.", null);
            }
            if (!selectedDepartment) {
                notifyCommandError("The system did not find the user selected department.", null);
            }
            if (selectedGroup && selectedDepartment) {

                const userNumber = (isUserEdited && user ? userDialogForm.information.userId : undefined);
                const bleMobileNumber = PhonesUtil.convertCountryCodeAndNumbersToPhoneNumber(userDialogForm.bluetoothForm.mobileCountryCode, userDialogForm.bluetoothForm.mobileNumber);
                const isAddUserToBleCloud = canUserBeAddedToNSPBleCloud(userNumber, bleMobileNumber);
                const userBleUserState = user?.UserAccess?.BleUserState ?? BluetoothUserStatuses.Undefined;
                const newUserBleUserState = isAddUserToBleCloud ? BluetoothUserStatuses.Activated : (isUserEdited ? userBleUserState : BluetoothUserStatuses.Undefined);

                const newUserModel: NewUserModel = {
                    Id: (isUserEdited && user ? user.Id : undefined),
                    UserNumber: userNumber,
                    GroupID: selectedGroup.GroupID,
                    Name: userDialogForm.information.name, 
                    DepartmentID: selectedDepartment.Id,
                    Telphone: userDialogForm.additionalInformation.telephone ?? "",
                    Department: {
                        DepartmentName: selectedDepartment.Name,
                        Id: selectedDepartment.Id,
                    },
                    Group: {
                        GroupName: selectedGroup.GroupName,
                        Id: selectedGroup.GroupID
                    },
                    UserAccess: {
                        BeginDate: userDialogForm.accessForm.isUnlimitedDateChecked ? null : DatesUtil.combineDateAndTimeToFormat(userDialogForm.accessForm.startDate, userDialogForm.accessForm.startTime),
                        EndDate: userDialogForm.accessForm.isUnlimitedDateChecked ? null : DatesUtil.combineDateAndTimeToFormat(userDialogForm.accessForm.endDate, userDialogForm.accessForm.endTime),
                        ReleaseTime: userDialogForm.bluetoothForm.lockReleaseTime ? Number(userDialogForm.bluetoothForm.lockReleaseTime) : Number(UserForm.INIT_LOCK_RELEASE_TIME),
                        BleUserState: newUserBleUserState,
                        MobileRemoteOpen: userDialogForm.bluetoothForm.remoteOpenDoors ?? false,
                        CanAuthorise: userDialogForm.bluetoothForm.temporaryAccess ?? false,
                        CanBookRoom: userDialogForm.bluetoothForm.meetingRoom ?? false,
                        CanAuthoriseExtended: userDialogForm.bluetoothForm.temporaryAccess ?? false,
                    },
                    UserRooms: userDialogForm.accessForm.userRooms,
                    DoorGroups: userDialogForm.accessForm.doorGroups,
                    Address: userDialogForm.additionalInformation?.address ?? "",
                    Nationality: userDialogForm.additionalInformation?.nationality,
                    Comment: userDialogForm.additionalInformation?.comment ?? "",
                    Postion: userDialogForm.additionalInformation?.position ?? "",
                    WorkType: userDialogForm.additionalInformation?.type ?? "",
                    Email: userDialogForm.bluetoothForm.email ?? "",
                    DateOfBirth: DatesUtil.convertDayjsToDateFormat(userDialogForm.additionalInformation.birthDate, DatesUtil.CUSTOM_DATE_FORMAT),
                    BleUserState: newUserBleUserState,
                    BleUserType: ConversionsUtil.getMobileUserTypeRequest(userDialogForm.bluetoothForm.mobileUser),
                    BleMobileNumber: bleMobileNumber ?? "",
                    BleUserName: userDialogForm.bluetoothForm.username ?? "",
                };

                updateAccessPageEndDateStorage(newUserModel, isAddUserToBleCloud);
            } 
        } else {
            closeValidation();
        }
    }

    const updateAccessPageEndDateStorage = (newUserModel: NewUserModel, isAddUserToBleCloud: boolean) => {
        if (!userDialogForm?.accessForm.isUnlimitedDateChecked) {
            fetchAccessEndDate(
                DatesUtil.combineDateAndTime(
                    userDialogForm?.accessForm.endDate,
                    userDialogForm?.accessForm.endTime
                ));
        }
        handleUserModification(newUserModel, isAddUserToBleCloud);
    }

    const handleUserModification = (newUserModel: NewUserModel, isAddUserToBleCloud: boolean) => {
        if (!isUserEdited) {
            createNewUser(actualSite, newUserModel).then((user) => addNewlyCreatedUsersToThePendingList(user, isAddUserToBleCloud));
        } else {
            updateExistingUser(actualSite, newUserModel).then((user) => addNewlyCreatedUsersToThePendingList(user, isAddUserToBleCloud));
        }
    }

    const addNewlyCreatedUsersToThePendingList = (user: UserModel | undefined, isAddUserToBleCloud: boolean) => {
        if (user) {
            dispatch(setNewUsersToPending([...listOfNewUsersOnPending, user]));
        }
        handleAddingUserToNSPBleCloud(user, isAddUserToBleCloud);
    }

    const handleAddingUserToNSPBleCloud = (user: UserModel | undefined, isAddUserToBleCloud: boolean) => {
        if (user) {
            if (isAddUserToBleCloud) {
                addUpdateBleUser(actualSite, user.Id);
            }
            uploadImageToUser(user);
        } else {
            handleSuccessfulValidationEnd();
        }
    }

    const uploadImageToUser = (user: UserModel | undefined) => {
        if (user && userDialogForm && userDialogForm.imageForm) {
            const handler = Object.freeze({
                [ImageActions.ImageAddition]: () => FilesUtil.convertImageToBase64(userDialogForm.imageForm?.imageFile, (base64String) => addUserImage(actualSite, user.Id, base64String).then(() => handleSuccessfulValidationEnd())),
                [ImageActions.ImageUpload]: () => FilesUtil.convertImageToBase64(userDialogForm.imageForm?.imageFile, (base64String) => deleteUserImage(actualSite, user.Id).then(() => addUserImage(actualSite, user.Id, base64String).then(() => handleSuccessfulValidationEnd()))),
                [ImageActions.ImageDeletion]: () => deleteUserImage(actualSite, user.Id).then(() => handleSuccessfulValidationEnd()),
            });
            handler[Number(userDialogForm.imageForm.imageAction)].call();
        } else {
            handleSuccessfulValidationEnd();
        }
    }

    const canUserBeAddedToNSPBleCloud = (userNumber: string | undefined, bleMobileNumber: string | null | undefined): boolean => {
        if (isBluetoothPageDisabled) {
            return false;
        }
        if (isUserEdited) {
            const userFromStorage = listOfUsers.find(item => item.UserNumber === userNumber);
            return userFromStorage?.UserAccess?.BleUserState === BluetoothUserStatuses.Undefined && !!bleMobileNumber;
        } else {
            return !!bleMobileNumber;
        }
    }

    const handleSuccessfulValidationEnd = () => {
        closeValidation();
        handleClickOnClose();
        setRefreshUserList();
    }

    React.useEffect(() => {
        if (isSubmitClicked) {
            handleClickOnSubmit();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitClicked]);

    return ( <React.Fragment></React.Fragment> );

};

const MapStateToProps = (state) => {
    return { lastUsedDateReducer: state.lastUsedDateReducer.lastUsedDate };
  };

const MapDispatchToProps = (dispatch) => {
    return { fetchAccessEndDate: (date: dayjs.Dayjs | undefined) => dispatch(fetchLastUsedDateRequester(date)) };
};

export default connect(MapStateToProps, MapDispatchToProps)(ValidateUserForm);