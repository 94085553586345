import { DatesUtil } from 'helper/DatesUtil';
import { getCardById } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import CardModel from 'model/CardModel';
import { DialogToOpen } from 'model/enums';
import OptionModel from 'model/OptionModel';
import UserModel from 'model/UserModel';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { setCardDialogForm } from 'redux/actions/cardDialogActions';
import { selectCardDialogForm } from 'redux/selectors/cardDialogSelector';
import { selectMasterLevelList } from 'redux/selectors/masterLevelSelector';
import { selectUserListStorage } from 'redux/selectors/userSelector';
import { selectDepartmentList } from 'redux/selectors/departmentSelector';
import { useRoomsInit } from './useStorageInits';

export const useCardDialogFormFiller = () => {
  const { openedDialog, userModels, newUsersOnPendingModels } = useSelector(selectUserListStorage);
  const masterLevels = useSelector(selectMasterLevelList);
  const listOfRooms = useRoomsInit();
  const listOfDepartments = useSelector(selectDepartmentList);
  const form = useSelector(selectCardDialogForm);
  const actualSite = ActualSiteLocator();
  const dispatch = useDispatch();

  useEffect(() => {
    if (form?.cardDetails?.user) {
      const selectedUserModel = [...userModels, ...newUsersOnPendingModels].find(
        (user) => user.Id === form.cardDetails.user?.userGlobalId,
      );
      if (selectedUserModel) {
        fetchCardOfTheSelectedUser(selectedUserModel, form.cardDetails.user);
      }
    }
  }, [form?.cardDetails.user]);

  const isCardAlreadyAddedToForm = (selectedUserModel: UserModel) => {
    return form?.userCard && selectedUserModel.Card?.CardID === form.userCard.Id;
  }

  const fetchCardOfTheSelectedUser = async (
    selectedUserModel: UserModel,
    selectedOption: OptionModel,
  ) => {
    if (
        openedDialog === DialogToOpen.CreateMasterCardDialog
        && !isCardAlreadyAddedToForm(selectedUserModel)
        && selectedUserModel?.Card
    ) {
        const cardModel = await getCardById(actualSite, Number(selectedUserModel.Card.CardID));
        if (cardModel) handleCardDialogFormUpdate(selectedUserModel, selectedOption, cardModel);
    }
    handleCardDialogFormUpdate(selectedUserModel, selectedOption, undefined);
  };

  const handleCardDialogFormUpdate = async (
    selectedUserModel: UserModel,
    selectedOption: OptionModel,
    selectedCardModel?: CardModel,
  ) => {
    if (form) {
      const beginDateAndTime = DatesUtil.convertFormatToDayjs(selectedUserModel.UserAccess?.BeginDate, DatesUtil.CUSTOM_ISO_FORMAT);
      const lastDateAndTime = DatesUtil.convertFormatToDayjs(selectedUserModel.UserAccess?.EndDate, DatesUtil.CUSTOM_ISO_FORMAT);
      dispatch(
        setCardDialogForm({
          cardDetails: {
            ...form.cardDetails,
            user: selectedOption,
            startDate: beginDateAndTime,
            startTime: beginDateAndTime,
            isStartDateChecked: !!beginDateAndTime,
            lastDate: lastDateAndTime,
            lastTime: lastDateAndTime,
            isLastDateChecked: !!lastDateAndTime,
            isUnlimitedDateChecked: !beginDateAndTime && !lastDateAndTime,
            lockReleaseTime: selectedUserModel.UserAccess?.ReleaseTime?.toString(),
            department: listOfDepartments.find(department => department.Id === selectedUserModel.Department.Id),
          },
          cardDoors: listOfRooms.filter((room) =>
              selectedUserModel?.UserRooms?.some(
                (UserRoom) => UserRoom.RoomId === room.Id,
              ),
          ),
          cardLevels: masterLevels.map((masterLevel) => ({
            masterLevel,
            isChecked: selectedCardModel
              ? selectedCardModel.Remark.includes(masterLevel.ID.toString())
              : false,
          })),
          userCard: selectedCardModel 
            ? selectedCardModel 
            : form.userCard,
        }),
      );
    }
  };
};
