import { ActualSiteLocator } from "constants/actualSiteLocator";
import Building from "model/Building";
import LockType from "model/LockType";
import RoomModel from "model/RoomModel";
import TimeGroup from "model/TimeGroup";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllBuildings } from "redux/actions/buildingActions";
import { fetchAllGroupName } from "redux/actions/groupNameActions";
import { fetchAllRooms, requestLockTypes } from "redux/actions/roomActions";
import { selectBuildingList } from "redux/selectors/buildingSelector";
import { selectGroupNameList } from "redux/selectors/groupNameSelector";
import { selectRoomList, selectRoomLockType } from "redux/selectors/roomSelector";

export const useBuildingsInit = () => {
    const [buildings, setBuildings] = useState<Building[]>([]);
    const listOfBuildings = useSelector(selectBuildingList);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    useEffect(() => {
        if (listOfBuildings && listOfBuildings.length === 0) {
            fetchAllBuildings(actualSite, dispatch);
        }
        setBuildings(listOfBuildings);
    }, [listOfBuildings]);

    return buildings;
}

export const useRoomsInit = () => {
    const [rooms, setRooms] = useState<RoomModel[]>([]);
    const listOfRooms = useSelector(selectRoomList);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    useEffect(() => {
        if (listOfRooms && listOfRooms.length === 0) {
            fetchAllRooms(actualSite, dispatch);
        }
        setRooms(listOfRooms);
    }, [listOfRooms]);

    return rooms;
}

export const useLockTypesInit = () => {
    const [lockTypes, setLockTypes] = useState<LockType[]>([]);
    const listOfLockTypes = useSelector(selectRoomLockType);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    useEffect(() => {
        if (listOfLockTypes && listOfLockTypes.length === 0) {
            requestLockTypes(actualSite, dispatch);
        }
        setLockTypes(listOfLockTypes);
    }, [listOfLockTypes]);

    return lockTypes;
}

export const useTimeGroupsInit = () => {
    const [timeGroup, setTimeGroup] = useState<TimeGroup[]>([]);
    const listOfGroups = useSelector(selectGroupNameList);
    const actualSite = ActualSiteLocator();
    const dispatch = useDispatch();

    useEffect(() => {
        if (listOfGroups && listOfGroups.length === 0) {
            fetchAllGroupName(actualSite, dispatch);
        }
        setTimeGroup(listOfGroups);
    }, [listOfGroups]);

    return timeGroup;
}