import * as React from 'react';
import styled from 'styled-components';
import { Dialog, DialogTitle } from '@mui/material';
import { blue, datePickerGrey, dialogMenuOptionBorderGray, white } from 'constants/theme';
import { DefaultIds, ReissueCardDialogFieldName } from 'model/enums';
import CheckboxField from 'components/controls/checkboxes/CheckboxField';
import ReissueCardData from 'model/ReissueCardData';
import MultiTypeInputField from 'components/controls/inputs/MultiTypeInputField';
import { StyledContentRowHolder, StyledUserDialogContent } from './userCardDialog/UserDialogCommonStyle';
import SingleDatePicker from 'components/controls/datePicker/SingleDatePicker';
import StyledButton from 'components/controls/button/StyledButton';
import { getCardById, reissueCard } from 'API/commands/CardCommands';
import { ActualSiteLocator } from 'constants/actualSiteLocator';
import SingleTimePicker from 'components/controls/datePicker/SingleTimePicker';
import dayjs from 'dayjs';
import { Divider } from '@mui/material';
import { DatesUtil } from 'helper/DatesUtil';
import { useSelector } from 'react-redux';
import { selectUser } from 'redux/selectors/userSelector';
import UserTableRecord from 'model/UserTableRecord';
import { notifyError } from 'helper/NotificationService';

type Props = {
    selectedUserTableRecordRow: UserTableRecord | undefined;
    open: boolean;
    handleClose: () => void;
    handleSubmit: () => void;
};

const ReissueCardDialog = ({
    selectedUserTableRecordRow,
    open,
    handleClose,
    handleSubmit,
}: Props) => {
    const [isLimitedDateChecked, setIsLimitedDateChecked] = React.useState<boolean>(false);
    const [isUnlimitedDateChecked, setIsUnlimitedDateChecked] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState<string | undefined>(undefined);
    const [reissueCardData, setReissueCardData] = React.useState<ReissueCardData | null>(null);
    const [cardCreatedDate, setCardCreatedDate] = React.useState<dayjs.Dayjs | null>();
    const actualSite = ActualSiteLocator();
    const userCard = useSelector(selectUser(selectedUserTableRecordRow?.globalId ?? -1))?.Card;
    const CARD_DATE = cardCreatedDate ? DatesUtil.convertDayjsToDateFormat(cardCreatedDate, DatesUtil.CUSTOM_DATE_FORMAT_REVERSED) : undefined;
    const CARD_TIME = cardCreatedDate ? DatesUtil.convertDayjsToDateFormat(cardCreatedDate, DatesUtil.CUSTOM_TIME_FORMAT) : undefined;

    const setCreationDateInformation = () => {
        if (userCard) {
            getCardById(actualSite, Number(userCard.CardID)).then((cardDetails => {
                if (cardDetails) {
                    setCardCreatedDate(DatesUtil.convertFormatToDayjs(cardDetails.CreatedOn, DatesUtil.CUSTOM_DATE_TIME_FORMAT));
                }
            }));
        } else {
            handleClose();
        }
    }

    const setReissueCardInformation = () => {
        setReissueCardData({
            cardId: userCard?.CardID ?? 'No Car ID',
            cardType: userCard?.CardType ?? 'No Card Type',
            expiryDateTime: DatesUtil.convertFormatToDayjs(userCard?.Expiry, DatesUtil.CUSTOM_DATE_TIME_FORMAT),
        });
    }

    React.useEffect(() => {
        if (open) {
            setCreationDateInformation();
            setReissueCardInformation();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open]);

    React.useEffect(() => {
        if (open) {
            setIsLimitedDateChecked(reissueCardData?.expiryDateTime !== null);
            setIsUnlimitedDateChecked(reissueCardData?.expiryDateTime === null);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reissueCardData])

    const handleLimitedDateOnCheck = () => {
        setIsLimitedDateChecked((prev) => !prev);
        setIsUnlimitedDateChecked(false);
        setErrorMessage(undefined);
    }

    const handleUnlimitedDateOnCheck = () => {
        if (!isUnlimitedDateChecked) {
            setIsLimitedDateChecked(false);
            setErrorMessage(undefined);
        };
        setIsUnlimitedDateChecked((prev) => !prev);
    }

    const handleSubmitOnClick = async () => {
        if (!isUnlimitedDateChecked && !isLimitedDateChecked) {
            notifyError("Either the Limited Date or the Unlimited Date must be checked!", "");
            return;
        }

        const formattedExpiryDate = reissueCardData?.expiryDateTime ? reissueCardData.expiryDateTime.format(DatesUtil.CUSTOM_DATE_FORMAT_REVERSED) : null;
        const formattedExpiryTime = reissueCardData?.expiryDateTime ? reissueCardData.expiryDateTime.format(DatesUtil.CUSTOM_TIME_FORMAT) : null;
        if (!isUnlimitedDateChecked
            && formattedExpiryDate !== null
            && formattedExpiryTime!== null
            && (reissueCardData?.expiryDateTime?.isBefore(dayjs()) || !reissueCardData?.expiryDateTime?.isValid())
        ) {
            setErrorMessage("The Limited Date must be in the future!");
            return;
        }

        setErrorMessage(undefined);
        const expiryDate = reissueCardData?.expiryDateTime && !isUnlimitedDateChecked
            ? reissueCardData?.expiryDateTime.format(DatesUtil.CUSTOM_FULL_DATE_TIME_FORMAT)
            : "";

        // TODO: In a later task, please change the values of the DefaultIds of the Operator and the Encoder.
        const res = await reissueCard(actualSite, DefaultIds.OperatorId, DefaultIds.EncoderId, Number(reissueCardData?.cardId), expiryDate);
        if (res) {
            handleSubmit();
        };
        handleClose();
    }

    const handleCancel = () => {
        setErrorMessage(undefined);
        setIsLimitedDateChecked(reissueCardData?.expiryDateTime !== null);
        setIsUnlimitedDateChecked(false);
        handleClose();
    }

    return (
        <StyledDialog
          open={open}
          onClose={() => handleClose()}
          disableScrollLock={true}
          PaperProps={{ component: 'form' }}
        >
            <StyledUserDialogContent
                height={400}
                width={536}
                style={{ display: 'flex', flexDirection: 'column', margin: 'auto' }}
            >
                <StyledDialogTitle>Reissue Card</StyledDialogTitle>
                <StyledContentRowHolder>
                    <MultiTypeInputField
                        inputFieldHeight={"40px"}
                        inputFieldWidth={"536px"}
                        displayedName={ReissueCardDialogFieldName.CardType}
                        inputValue={reissueCardData?.cardType}
                        inputType='string'
                        isDisabled={true}
                    />
                </StyledContentRowHolder>
                <StyledContentRowHolder>
                    <StyledCardCreationDateHolder>
                        <StyledContent width={"200px"}>
                            {ReissueCardDialogFieldName.StartDate}
                        </StyledContent>
                        <StyledContentWrapper width='336px'>
                            <StyledContent>
                                {CARD_DATE}
                            </StyledContent>
                            <StyledContent>
                                {CARD_TIME}
                            </StyledContent>
                        </StyledContentWrapper>
                    </StyledCardCreationDateHolder>
                </StyledContentRowHolder>
                <StyledDivider />
                <StyledContentRowHolder>
                    <StyledCheckBoxHolder>
                        <CheckboxField
                            boxWidth={"200px"}
                            boxHeight={"40px"}
                            displayedName={ReissueCardDialogFieldName.LimitedDate}
                            setValue={handleLimitedDateOnCheck}
                            inputValue={isLimitedDateChecked}
                        />
                    </StyledCheckBoxHolder>
                    <StyledDateHolder>
                        <SingleDatePicker 
                            inputFieldWidth={"160px"}
                            inputFieldHeight={"40px"}
                            setValue={(expiryDateTime) => {
                                if (reissueCardData) setReissueCardData({...reissueCardData, expiryDateTime})
                            }}
                            inputValue={reissueCardData?.expiryDateTime}
                            hasLabel={false}
                            isDisabled={!isLimitedDateChecked}
                            invalidFormMessage={errorMessage}
                        />
                        <StyledDateTimePickerContainer $isError={!!errorMessage}>
                            <SingleTimePicker
                                inputFieldWidth={"160px"}
                                inputFieldHeight={"40px"}
                                setValue={(expiryDateTime) => {
                                    if (reissueCardData) setReissueCardData({...reissueCardData, expiryDateTime})
                                }}
                                inputValue={reissueCardData?.expiryDateTime}
                                hasLabel={false}
                                isDisabled={!isLimitedDateChecked}
                            />
                        </StyledDateTimePickerContainer>
                    </StyledDateHolder>
                </StyledContentRowHolder>
                <StyledContentRowHolder>
                    <StyledCheckBoxHolder>
                        <CheckboxField
                            boxWidth={"220px"}
                            boxHeight={"40px"}
                            displayedName={ReissueCardDialogFieldName.UnlimitedDate}
                            setValue={handleUnlimitedDateOnCheck}
                            inputValue={isUnlimitedDateChecked}
                        />
                    </StyledCheckBoxHolder>
                </StyledContentRowHolder>
                <ButtonContainer>
                    <StyledButton
                        buttonHeight={37}
                        buttonWidth={95}
                        displayedName={"Cancel"}
                        handleOnClick={handleCancel}
                        backgroundColor={white}
                    />
                    <StyledButton
                        handleOnClick={handleSubmitOnClick}
                        displayedName={"Reissue Card"}
                        buttonHeight={37}
                        buttonWidth={137}
                        isFilledButton={true}
                        backgroundColor={blue}
                        style={{ marginRight: '0' }}
                    />
                </ButtonContainer>
            </StyledUserDialogContent>
        </StyledDialog>
    );
};

export default ReissueCardDialog;

const StyledCheckBoxHolder = styled.div` padding-top: 16px `;

const StyledContent = styled.div<{ width?: string }>((props) => ({ width: props.width ?? "160px"}));

const StyledContentWrapper = styled.div<{ width: string }>((props) => ({
    width: props.width,
    display: 'flex',
    justifyContent: 'space-between'
}));

const StyledDateTimePickerContainer = styled.div<{ $isError?: boolean }>((props) => ({ paddingBottom: props.$isError ? "16px" : "0px" }));

const StyledCardCreationDateHolder = styled.div`
    padding-top: 32px;
    display: flex;
    height: 40px;
    font-weight: 400 !important;
    line-height: 24.35px !important;
`;

export const StyledDivider = styled(Divider)`
    height: 16px;
    margin-bottom: 16px;
    border-color: ${dialogMenuOptionBorderGray};
`;

const StyledDateHolder = styled.div`
    display: flex;
    gap: 16px;
    align-items: center;
`;

const StyledDialog = styled(Dialog)`
    justify-content: center;
    form {
        height: 450px;
        width: 600px;
        border-radius: 20px;
        border: 1px solid ${datePickerGrey};
        background-color: ${white};
        overflow-x: hidden;
    }
`;

const StyledDialogTitle = styled(DialogTitle)`
    font-family: "Inter";
    font-weight: 500;
    font-size: 22px;
    line-height: 26.63px;
    text-align: center;
`;

const ButtonContainer = styled.div`
    display: flex;
    justify-content: end;
    margin-top: auto;
    gap: 16px;
`;