import * as React from 'react';
import styled from 'styled-components';
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip';
import { darkGray, lighterGrey, lightGrey, white } from 'constants/theme';

export const StyledTextOverflowContainer = styled.div<{ 
    width?: number, 
    height?: number, 
    overflow?: string,
}>((props) => ({
    maxWidth: `${props.width}px`,
    maxHeight: `${props.height}px`,
    textOverflow: `${props.overflow}`,
    overflow: `hidden`,
    whiteSpace: `nowwrap`,
}));

export const PLACING_TOOLTIP_TO_TOP = "top";

export const LightTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: darkGray,
      fontSize: '10px',
      lineHeight: '15.22px',
      fontFamily: 'Inter',
      borderRadius: '10px',
      marginBottom: '0px !important',
      position: 'fixed',
      bottom: '5px',
      right: '-10px',
      width: 'max-content',
    },
}));

export const setOverflowContainerWithText = (
    elementIdToSearchBy: string, 
    inputText: string | undefined, 
    containerHeight: number = 18, 
    containerWidth: number = 131, 
    containerOverflow: string = 'ellipsis') => {
    return(
        <StyledTextOverflowContainer
          height={containerHeight}
          width={containerWidth}
          overflow={containerOverflow}
          id={elementIdToSearchBy}
        >
          { isInputTextOverflown(elementIdToSearchBy) ? 
            <LightTooltip 
              title={inputText} 
              placement={PLACING_TOOLTIP_TO_TOP}
            >
              <span>{inputText}</span>
            </LightTooltip>
          : 
            <span>{inputText}</span>
           }
        </StyledTextOverflowContainer>
    );
  }

const isOverflown = (element: HTMLElement ) => {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
  }

export const isInputTextOverflown = (elementIdToSearchBy: string) => {
    const htmlElement = document.getElementById(elementIdToSearchBy);
    return htmlElement && isOverflown(htmlElement);
}

export const REQUIRED_FORM_FIELD_SYMBOL_STAR = '*';

export const displayInputName= (displayedName: string, isRequired: boolean = false, requiredFormFieldSymbol: string = REQUIRED_FORM_FIELD_SYMBOL_STAR) => {
    return `${displayedName}${(isRequired ? requiredFormFieldSymbol : '')}`;
}

export const SelectFieldMenuProps = (itemHeight: number = 40, itemPaddingTop: number = 8) => {
    return {
        PaperProps: {
            style: {
                maxHeight: itemHeight * 4.5 + itemPaddingTop,
                width: 250,
            },
            sx: {
                '&::-webkit-scrollbar': {
                    width: '10px',
                    border: `10px solid ${white}`,
                    padding: '10px'
                },
                '&::-webkit-scrollbar-track': {
                    background: `${white}`,
                    borderRadius: '10px'
                },
                '&::-webkit-scrollbar-thumb': {
                    background: lightGrey,
                    borderRadius: '6px',
                    '&:hover': { background: lighterGrey }
                }
            }
        },
        disableScrollLock: true
    };
}