import * as React from 'react';
import styled from 'styled-components';
import { StyledContentRowHolder } from '../../userCardDialog/UserDialogCommonStyle';
import { blue } from 'constants/theme';
import StyledButton from 'components/controls/button/StyledButton';
import add from 'media/add.svg';
import SearchBarField from 'components/controls/searchBar/SearchBarField';
import { CardDialogCardDetailsInputFieldNames, DialogToOpen } from 'model/enums';
import { useSelector } from 'react-redux';
import { selectLastUserToAddedToNewUsersOnPending, selectOpenedDialog } from 'redux/selectors/userSelector';
import { useIsCardFieldDisplayed } from 'hooks/useIsInputDisplayed';
import OptionModel from 'model/OptionModel';
import { selectCardDialogStorage } from 'redux/selectors/cardDialogSelector';
import { isUserDialogOpened } from 'helper/DialogUtils';
import { useDispatch } from 'react-redux';
import { setUsersIsRefreshUsersList } from 'redux/actions/userActions';
import UserCardDialog from '../../userCardDialog/UserCardDialog';
import { convertSingleUserModelToOptionModel } from 'hooks/useConversion';
import { CardDetailsForm } from 'model/CardDialogForm';

type Props = {
    fieldWidth: string;
    listOfUserOptions: OptionModel[];
    setForm: (cardDetails?: CardDetailsForm) => void;
};

const SearchAndAddUserRow = ({fieldWidth, listOfUserOptions, setForm}: Props) => {

    const [dialogToOpen, setDialogToOpen] = React.useState<DialogToOpen>();
    
    const lastUserToAddedToNewUsersOnPending = useSelector(selectLastUserToAddedToNewUsersOnPending);
    const { form, formError } = useSelector(selectCardDialogStorage);
    const openedDialog = useSelector(selectOpenedDialog);
    const inputDisplayer = useIsCardFieldDisplayed();

    const dispatch = useDispatch();
    
    const fetchSearchBarWidth = () => {
        switch(openedDialog) {
            case DialogToOpen.CreateFloorCardDialog:
                return "741px";
            case DialogToOpen.CreateEmergencyCardDialog:
                return "937px";
            default:
                return fieldWidth;
        }
    }

    React.useEffect(() => {
        if (lastUserToAddedToNewUsersOnPending) {
            setForm({
                ...form?.cardDetails,
                user: convertSingleUserModelToOptionModel(lastUserToAddedToNewUsersOnPending)
            });
        }
    }, [lastUserToAddedToNewUsersOnPending])

    return (
        <React.Fragment>
            <SearchBarField
                searchBarHeight={"42px"}
                searchBarWidth={fetchSearchBarWidth()}
                searchbarLabel={CardDialogCardDetailsInputFieldNames.UserSearchBar}
                setValue={(user) => setForm({...form?.cardDetails, user})}
                selectOptions={listOfUserOptions}
                inputValue={form?.cardDetails.user}
                setInputChange={(user) => setForm({...form?.cardDetails, user})}
                handleOnEnter={() => setForm({...form?.cardDetails, user: undefined})}
                invalidFormMessage={formError?.cardDetails.user}
                forceOptionSelection
                isRequired
            />
            { inputDisplayer.isUserButtonDisplayed ?
                <StyledButtonHolder
                    $isItemsAlignToCenter={!!formError?.cardDetails.user}
                >
                    <StyledButton
                        handleOnClick={() => setDialogToOpen(DialogToOpen.CreateUserDialog)}
                        displayedName={CardDialogCardDetailsInputFieldNames.AddUser}
                        buttonHeight={40}
                        buttonWidth={171}
                        isFilledButton
                        backgroundColor={blue}
                        isIconPositionedAtStart
                        icon={add}
                    />
                </StyledButtonHolder>
            : null}
            <UserCardDialog
                openAddUserDialog={isUserDialogOpened(dialogToOpen)}
                setOpenAddUserDialog={() => setDialogToOpen(undefined)}
                dialogToOpen={dialogToOpen}
                setRefreshUserList={() => {
                    // In this case, the users will only be refreshed when the opened CardDialog is Closed.
                    dispatch(setUsersIsRefreshUsersList(true));
                }}
            />
        </React.Fragment>
    );
};

export default SearchAndAddUserRow;

const StyledButtonHolder = styled(StyledContentRowHolder)<{ $isItemsAlignToCenter?: boolean }>((props) => ({
    display: "flex",
    alignItems: props.$isItemsAlignToCenter ? "center" : "end",
}));
