import * as React from 'react';
import { ReactComponent as ShevronLeftArrow} from 'media/chevron-left.svg';
import { CustomStyledDialogContentHolder, StyledButtonHolder, StyledDialogActions, StyledDialogActionsHolder, StyledDialogCardHolder } from "../../UserCardDialog";
import { CustomDialogBackIcon, CustomDialogTitleComponent, CustomStyledDialogCardTitle } from '../../UserDialogCommonStyle';
import StyledButton from 'components/controls/button/StyledButton';
import { blue, white } from 'constants/theme';
import { dialogContainer } from 'constants/globalStyle';
import { Dialog } from '@mui/material';
import CustomUserFilter from 'components/cards/CustomUserFilter';
import { DefaultFilterValues, FilterName } from 'model/enums';
import { useLockTypesInit } from 'hooks/useStorageInits';
import styled from 'styled-components';

type Props = {
    isDialogOpen: boolean;
    setDialogOpen: (isDialogOpen: boolean) => void;
    isNoActionDialog: boolean;
    dialogResourceName: string;
    MainDialogComponent: React.ReactNode;
    handleConfirm?: () => void;
    selectedLockType?: (lockType: string) => void;
    selectedResourceNumber?: number;
    isHeaderDisplayed?: boolean;
};

const BaseUserAccessDialog = ({ 
    isDialogOpen,
    setDialogOpen,
    isNoActionDialog,
    dialogResourceName,
    MainDialogComponent,
    handleConfirm,
    selectedLockType,
    selectedResourceNumber,
    isHeaderDisplayed,
}: Props) => {

    const [selectedFilter, setSelectedFilter] = React.useState<string>(DefaultFilterValues.LockTypeFilter);
    const [isFilterOpen, setFilterOpen] = React.useState(false);
    const [filterOptions, setFilterOptions] = React.useState<string[]>([DefaultFilterValues.LockTypeFilter]);
    const [selectedFilterIndex, setSelectedFilterIndex] = React.useState(0);
    const listOfLockTypes = useLockTypesInit();

    const handleClickOnClose = () => {
        setDialogOpen(false);
    };

    React.useEffect(() => {
        setFilterOptions([
            DefaultFilterValues.LockTypeFilter, 
            ...listOfLockTypes.map(lockType => lockType.TypeName)
        ]);
    }, [selectedFilter, listOfLockTypes]);

    React.useEffect(() => {
        if (selectedLockType) selectedLockType(selectedFilter)
    }, [selectedFilter]);

    return (
        <Dialog
            open={isDialogOpen}
            onClose={handleClickOnClose}
            disableScrollLock={true}
            sx={{".MuiPaper-root" : dialogContainer()}}
        >
            <StyledDialogCardHolder>
            <CustomStyledDialogCardTitle>
                    <CustomDialogTitleComponent>
                        <CustomDialogBackIcon 
                            onClick={handleClickOnClose}
                        >
                            <ShevronLeftArrow />
                        </CustomDialogBackIcon>
                    </CustomDialogTitleComponent>
                    <div>
                        {dialogResourceName}
                    </div>
                    <CustomDialogTitleComponent />
                </CustomStyledDialogCardTitle>
                { isHeaderDisplayed ? 
                    <StyledFilterHolder>
                        <CustomUserFilter
                            filterValue={selectedFilter}
                            filterOnChangeHandle={(event: any) => setSelectedFilter(event.target.value)}
                            filterOnOpenHandle={() => setFilterOpen(true)}
                            filterOnCloseHandle={() => setFilterOpen(false)}
                            filterName={FilterName.LockType}
                            filterOptions={filterOptions}
                            isFilterOpen={isFilterOpen}
                            setSelectedFilterOptionIndex={setSelectedFilterIndex}
                            selectedFilterOptionIndex={selectedFilterIndex}
                            width={"200px"}
                            height={"40px"}
                        />
                    </StyledFilterHolder>
                : null }
                <CustomStyledDialogContentHolder
                    $isHeaderDisplayed={isHeaderDisplayed}
                >
                    {MainDialogComponent}
                </CustomStyledDialogContentHolder>
                <StyledDialogActions>
                    <StyledDialogActionsHolder>
                        <StyledButtonHolder>
                            {!isNoActionDialog && handleConfirm ? 
                                <>
                                    <StyledButton
                                        buttonHeight={37}
                                        buttonWidth={95}
                                        displayedName={"Go Back"}
                                        handleOnClick={handleClickOnClose}
                                        backgroundColor={white}
                                    />
                                    <StyledButton
                                        buttonHeight={37}
                                        buttonWidth={130}
                                        displayedName={`Add ${dialogResourceName}` + (selectedResourceNumber ? ` (${selectedResourceNumber})` : '')}
                                        handleOnClick={handleConfirm}
                                        backgroundColor={blue}
                                        isFilledButton={true}
                                    />
                                </> :
                                <StyledButton
                                    buttonHeight={37}
                                    buttonWidth={95}
                                    displayedName={"Cancel"}
                                    handleOnClick={handleClickOnClose}
                                    backgroundColor={white}
                                /> 
                            }
                        </StyledButtonHolder>
                    </StyledDialogActionsHolder>
                </StyledDialogActions>
            </StyledDialogCardHolder>
        </Dialog>
    );
};

export default BaseUserAccessDialog;

const StyledFilterHolder = styled.div`
    display: flex;
    justify-content: space-between; 
    margin: 24px 32px 24px 32px;
`;
